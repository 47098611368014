import { IconButton, Menu } from "@kaltura/ds-react-components";
import { MouseEvent, useState } from "react";
import { MoreHorizontal24Icon } from "@kaltura/ds-react-icons";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { getComponentFromPath, isMobileDevice, translate } from "@kaltura/mediaspace-shared-utils";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { KmsTypeComponent } from "@kaltura/mediaspace-shared-types";
import { ErrorBoundary } from "@kaltura/mediaspace-shared-ui";

export interface ActionsMenuProps {
    items: KmsTypeComponent[];
}

/**
 * Media actions menu (three dots, with items like "edit", "delete" and "publish")
 */
export const ActionsMenu = ({ items }: ActionsMenuProps) => {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    const [itemState, setItemState] = useState<Record<string, unknown>[]>([]);
    const open = !!anchorEl;
    const close = () => setAnchorEl(null);

    const sendButtonAnalytics = useButtonAnalytics();

    const itemsToShow = items.filter((item) => (isMobileDevice ? !item.props.hideOnMobile : true));

    if (!itemsToShow.length) {
        return null;
    }

    const buttonId = "media-actions-button";
    const menuId = "media-actions-menu";

    /**
     * allow menu items to have menu level state.
     * its being sent back to the same menu item as props.
     */
    const handleItemStateChange = (itemStateId: number, key: string, value: any) => {
        setItemState((current) => ({
            ...current,
            [itemStateId]: { ...current[itemStateId], [key]: value },
        }));
    };

    const onMenuButtonClick = (ev: MouseEvent<HTMLElement>) => {
        setAnchorEl(ev.currentTarget);
        sendButtonAnalytics("Media Actions Menu", ButtonClickAnalyticsType.MENU);
    };

    return (
        <>
            <IconButton
                id={buttonId}
                variant={"borderless"}
                size={"medium"}
                color={"secondary"}
                onClick={onMenuButtonClick}
                onKeyDown={(ev) => {
                    if (ev.key === "ArrowDown" || ev.key === " ") {
                        setAnchorEl(ev.currentTarget);
                    }
                }}
                aria-haspopup={true}
                aria-expanded={open}
                aria-controls={menuId}
                aria-label={translate("show actions")}
            >
                <MoreHorizontal24Icon />
            </IconButton>

            <Menu
                id={menuId}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                open={open}
                onClose={close}
                MenuListProps={{
                    "aria-labelledby": buttonId,
                }}
            >
                {itemsToShow.map(({ component, props }, index) => {
                    // get relevant menu item state and pass it as props
                    const itemStateProps = itemState[index];
                    const Component = getComponentFromPath(component);

                    return (
                        <ErrorBoundary key={index} fallback={<></>}>
                            <Component
                                {...props}
                                {...itemStateProps}
                                onCloseMenu={close}
                                onItemStateChange={(key: string, value: any) =>
                                    handleItemStateChange(index, key, value)
                                }
                                autoFocus={index === 0}
                            />
                        </ErrorBoundary>
                    );
                })}
            </Menu>
        </>
    );
};
