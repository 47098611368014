import { createContext, useContext } from "react";

export type AsyncJobsContextValue = {
    /**
     * should the checker check for async jobs status
     */
    shouldCheckAsyncJobsStatus: boolean;

    /**
     * start polling for async jobs status
     * to be used by apps that want to start polling NOT on page load
     */
    startPolling: () => void;

    /**
     * reset the state of the async jobs checker after polling has successfully completed
     * (to be triggered by the checker component)
     */
    resetStatus: () => void;
};

export const AsyncJobsContextDefaultValue: AsyncJobsContextValue = {
    shouldCheckAsyncJobsStatus: false,
    startPolling: () => {
        throw new Error("No AsyncJobsProvider");
    },
    resetStatus: () => {
        throw new Error("No AsyncJobsProvider");
    },
};

/**
 * React context for controlling server-polling for async jobs completion.
 * @see AsyncJobsProvider.
 */
export const AsyncJobsContext = createContext<AsyncJobsContextValue>(AsyncJobsContextDefaultValue);

/**
 * Hook for accessing the AsyncJobsContext
 */
export const useAsyncJobsContext = (): AsyncJobsContextValue => {
    return useContext(AsyncJobsContext);
};
