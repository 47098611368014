import React, { Suspense, useMemo, useEffect } from "react";
import { baseUrl, fetchKmsData, translate } from "@kaltura/mediaspace-shared-utils";
import { ErrorBoundary } from "@kaltura/mediaspace-shared-ui";
import { KmsTypePublishCategory } from "@kaltura/mediaspace-shared-types";
import { ChannelsTabForm, ChannelsTabFormProps } from "./ChannelsTabForm";
import { CategorySearchSkeleton } from "../category-search/CategorySearchSkeleton";
import { NoResults } from "../category-search/no-results/NoResults";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";

interface ChannelsTabSearchResultsFormProps extends ChannelsTabFormProps {
    searchPublishChannels: () => { results: KmsTypePublishCategory[] };
    isBulk?: boolean;
}

/**
 * channel tab search reults checkboxes
 */
function ChannelsTabSearchResultsForm(props: ChannelsTabSearchResultsFormProps) {
    const { searchPublishChannels, isBulk } = props;
    const { results } = searchPublishChannels();

    const sendButtonAnalytics = useButtonAnalytics();

    useEffect(
        () =>
            sendButtonAnalytics(
                `Publish - Set publishing settings modal box - search for channel ${isBulk && "(bulk)"}`,
                ButtonClickAnalyticsType.SEARCH
            ),
        [isBulk, sendButtonAnalytics]
    );

    const hasResults = results && results.length > 0;

    return (
        <>
            {hasResults && <ChannelsTabForm {...props} userCategories={{}} channelResults={results} />}
            {!hasResults && <NoResults title={translate("No channels found")} />}
        </>
    );
}

interface ChannelsTabFormContainerProps extends ChannelsTabFormProps {
    keyword: string;
    isBulk?: boolean;
}

/**
 * Publish modal Channels tab suspense Container - handles channels search
 */
export function ChannelsTabFormContainer(props: ChannelsTabFormContainerProps) {
    const { keyword } = props;

    // fetch data from kms
    const searchPublishChannels = useMemo(
        () =>
            keyword
                ? fetchKmsData<{ results: KmsTypePublishCategory[] }>(
                      baseUrl + "/publishmedia/category/search/",
                      {
                          keyword: keyword,
                          type: "channels",
                      }
                  )
                : undefined,
        [keyword]
    );

    return (
        <>
            {!keyword && <ChannelsTabForm {...props} />}
            {keyword && (
                <ErrorBoundary fallback={<CategorySearchSkeleton />}>
                    <Suspense fallback={<CategorySearchSkeleton />}>
                        <ChannelsTabSearchResultsForm searchPublishChannels={searchPublishChannels!} {...props} />
                    </Suspense>
                </ErrorBoundary>
            )}
        </>
    );
}
