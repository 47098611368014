import React from "react";
import styled from "@emotion/styled";
import { Typography, Link } from "@kaltura/ds-react-components";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType, KmsTypeComponent } from "@kaltura/mediaspace-shared-types";
import { Box } from "@kaltura/mediaspace-shared-styled";
import { ErrorBoundary } from "../error-boundary/ErrorBoundary";
import { getComponentFromPath } from "@kaltura/mediaspace-shared-utils";

export interface ListTitleProps {
    translatedTitle?: string;
    translatedLinkString?: string;
    titleLink?: string;
    linkAriaLabel?: string;
    titleClassName?: string;
    openTitleInNewTab?: boolean;
    analyticsType?: ButtonClickAnalyticsType;
    analyticsActionText?: string;
    titleAreaButtons?: KmsTypeComponent[];
    id?: string;
}

const TitleLine = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
}));

const TitleLineTitle = styled(Typography)(({ theme }) => ({
    margin: 0,
    lineHeight: "normal",
}));

const StyledTitleButtonsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(2),
}));

/**
 * Title used for components that show a partial list of entries, such as
 * a playlist or category content, with a configurable "show all" link
 *
 * @param props
 * @constructor
 */
export const ListTitle = (props: ListTitleProps) => {
    const {
        translatedTitle = "",
        translatedLinkString = "",
        titleLink = "",
        linkAriaLabel = "",
        openTitleInNewTab = false,
        analyticsType = ButtonClickAnalyticsType.NAVIGATE,
        analyticsActionText,
        titleAreaButtons = [],
        id,
    } = props;

    const baseClassName = "kms-ds-homepage-component-title";

    const sendButtonAnalytics = useButtonAnalytics();

    const sendAnalytics = () => {
        if (analyticsActionText) {
            sendButtonAnalytics(analyticsActionText, analyticsType);
        }
    };

    return (
        <TitleLine className={baseClassName}>
            {translatedTitle && (
                <TitleLineTitle variant={"h3"} component={"h2"} id={id}>
                    {translatedTitle}
                </TitleLineTitle>
            )}
            <StyledTitleButtonsContainer>
                {titleAreaButtons?.map(({ name, component, props }) => {
                    const Component = getComponentFromPath(component);
                    return (
                        <ErrorBoundary key={name} fallback={<></>}>
                            <Component {...props} className={`${baseClassName}-button`} />
                        </ErrorBoundary>
                    );
                })}
                {translatedLinkString && titleLink && (
                    <Link
                        href={titleLink}
                        target={openTitleInNewTab ? "_blank" : "_self"}
                        aria-label={linkAriaLabel || undefined}
                        onClick={() => sendAnalytics()}
                        size={"small"}
                    >
                        {translatedLinkString}
                    </Link>
                )}
            </StyledTitleButtonsContainer>
        </TitleLine>
    );
};

export default ListTitle;
