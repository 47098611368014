import { FunctionComponent, MouseEvent, useState } from "react";
import { IconButton, Menu, MenuItem, menuClasses, Tooltip } from "@kaltura/ds-react-components";
import { User24Icon } from "@kaltura/ds-react-icons";
import { addBaseUrl, getComponentFromPath, translate } from "@kaltura/mediaspace-shared-utils";
import { KmsTypeLinkLink } from "@kaltura/mediaspace-shared-types";
import { Divider } from "@kaltura/mediaspace-shared-ui";
import styled from "@emotion/styled";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { KmsTypeComponent } from "@kaltura/mediaspace-shared-types";

export type UserMenuItem = KmsTypeLinkLink | "DIVIDER" | KmsTypeComponent;

export type UserMenuProps = {
    className?: string;
    disableScrollLock?: boolean;
    items: UserMenuItem[];
};

const StyledMenuItem = styled(MenuItem)({
    // override eventsplatform css
    ".eventsplatform &": {
        color: "inherit",
        [`&:hover`]: {
            color: "inherit",
        },
    },
    // override v2ui and bootstrap
    [`a&`]: {
        color: "inherit",
        [`&:hover, &:focus`]: {
            color: "inherit",
            textDecoration: "none",
        },
    },
});

const StyledMenu = styled(Menu)(({ theme }) => ({
    [`& .${menuClasses.paper}`]: {
        [theme.breakpoints.down("sm")]: {
            top: "10px",
            maxHeight: "150px",
        },
    },
}));

const renderItem = (
    item: UserMenuItem,
    index: number,
    sendButtonAnalytics: (actionName: string, buttonType: number) => void
) => {
    if (item === "DIVIDER") {
        return <Divider key={`divider_${index}`} />;
    }

    const handleMenuItemClick = () => {
        if ("attributes" in item || "value" in item) {
            sendButtonAnalytics(
                `Header Menu - ${item.attributes?.["data-analytics-value"] ?? item.value}`,
                ButtonClickAnalyticsType.NAVIGATE
            );
        }
    };

    // custom menu item component
    if ("component" in item) {
        const { component, props: componentProps } = item;
        const ItemComponent = getComponentFromPath(component);
        return <ItemComponent {...componentProps} key={index} />;
    }

    return (
        <StyledMenuItem
            key={index}
            component={"a"}
            href={addBaseUrl(item.href)}
            target={(item.attributes?.target as string) ?? "_self"}
            rel={(item.attributes?.rel as string) ?? ""}
            onClick={handleMenuItemClick}
        >
            {item.label}
        </StyledMenuItem>
    );
};

const UserMenuButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.common.white,
    [`&:hover`]: {
        backgroundColor: "initial",
    },
}));

/**
 * User Menu component
 */
const UserMenu: FunctionComponent<UserMenuProps> = ({ className = "", disableScrollLock = false, items }) => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const sendButtonAnalytics = useButtonAnalytics();

    const handleButtonClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={`kms-ds-user-menu ${className}`}>
            <div id="userMenuToggle">
                <Tooltip title={translate("user menu")} placement={"bottom-start"}>
                    <UserMenuButton
                        color="translucent"
                        id={"userMenuToggleBtn"}
                        aria-haspopup={true}
                        aria-expanded={open ? "true" : "false"}
                        onClick={handleButtonClick}
                        aria-label={translate("user menu")}
                        {...(open && { "aria-controls": "userMenuToggleMenu" })}
                    >
                        <User24Icon />
                    </UserMenuButton>
                </Tooltip>
                <StyledMenu
                    id={"userMenuToggleMenu"}
                    anchorEl={anchorEl}
                    open={open}
                    onClose={handleMenuClose}
                    disableScrollLock={disableScrollLock}
                    anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "right",
                    }}
                    transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                    }}
                    MenuListProps={{
                        "aria-labelledby": "userMenuToggleBtn",
                    }}
                >
                    {items.map((item, index) => renderItem(item, index, sendButtonAnalytics))}
                </StyledMenu>
            </div>
        </div>
    );
};

export default UserMenu;
