import { ComponentType, Fragment } from "react";

export const isUndefined = (item: any) => typeof item === "undefined";

export const noop = () => {};

export const guid = () =>
    Math
        .floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);

export const encodeEntryNameForURI = (name: string): string => {
    // Do the same encoding done by KMS in Kms_View_Helper_EntryLink::sanitizeParamForUrl
    name = encodeURIComponent(name.replace("/", " "));
    name = name.replace("%2F", "");
    name = name.replace("%25", "");
    name = name.replace("%23", "");
    name = name.replace("%3", "");
    name = name.replace("%5C", "");

    // Deal with characters not covered by encodeURIComponent to align with urlencode done by KMS
    name = name.replace("!", "%21");
    name = name.replace("*", "%2A");
    name = name.replace("'", "%27");
    name = name.replace("(", "%28");
    name = name.replace(")", "%29");

    return name;
};

/**
 * use encodeURIComponent to get percent-encoded UTF-8
 * convert the percent encodings into raw bytes which can be fed into btoa.
 * @param str
 */
export const encodeUnicode = (str: string) => {
    return btoa(
        encodeURIComponent(str).replace(
            /%([0-9A-F]{2})/g,
            function toSolidBytes(match: string, p1: number) {
                return String.fromCharCode(Number("0x" + p1));
            }
        )
    );
};

/**
 * get query param from query string by name
 * @param name
 * @param url
 */
export const getQueryParamByName = (name: string, url = window.location.href) => {
    url = decodeURIComponent(url);
    name = name.replace(/[[\]]/g, "\\$&");
    const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)");
    const results = regex.exec(url);
    if (!results) {
        return null;
    }
    if (!results[2]) {
        return "";
    }
    return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * generates a unique id
 * @returns {string}
 */
export const generateGUID = () => {
    const S4 = function () {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    };
    return S4() + S4() + "-" + S4() + "-" + S4() + "-" + S4() + "-" + S4() + S4() + S4();
};

export const makeAlphaNumeric = (input: string) => input.replace(/\W/g, "");

/**
 * get the component object reference from its path.
 * NOTE the component code should be on the page before calling this function
 * if `path` is not a string, it is assumed to be the component reference.
 */
export function getComponentFromPath(path: ComponentType<any> | string): ComponentType<any> {
    if (typeof path === "string") {
        const parts = path.split(".");
        try {
            return parts.reduce(
                (previousValue: any, currentValue: string) => previousValue[currentValue],
                window as any
            );
        }
        catch (e) {
            console.warn(`failed to get component from path: ${path}`, e);
        }
        return Fragment;
    }
    // path is a reference to the component itself
    return path;
}
