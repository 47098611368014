import { useEffect, useRef } from "react";
import { addBaseUrl, getDataFromKms, translate } from "@kaltura/mediaspace-shared-utils";
import { useAsyncJobsContext, useToastsContext } from "@kaltura/mediaspace-shared-contexts";

/**
 * @see Kms_Enum_AsyncJobsEntityType in KMS server repository
 */
type EntityType = "category" | "channel" | "entry" | "user" | "userEntry" | "group" | "groupUser";

/**
 * @see Kms_Type_AsyncJob in KMS server repository
 */
type AsyncJob = {
    /**
     * async job id in back-end
     */
    id: number;

    /**
     * the type of the entity that this job concerns,  i.e. category, channel, entry or user
     */
    entityType: EntityType;

    /**
     * id of the entity this job concerns (entry id, category id, etc)
     */
    entityId: string | number;

    /**
     * entity name
     */
    entityName: string;

    /**
     * arbitrary job name, e.g. "approve entries", displayed in messages to end users
     */
    name: string;

    /**
     * custom kms event name, e.g. "asyncDone.KMS"
     */
    event?: string;
};

type AsyncJobsResponse = {
    finishedEventJobs: AsyncJob[];
    finishedJobs: AsyncJob[];
    runningJobs: AsyncJob[];

    /**
     * not used in react implementation
     */
    messageHtml: string;
};

export const AsyncJobsChecker = () => {
    const { shouldCheckAsyncJobsStatus, resetStatus } = useAsyncJobsContext();
    const { showToast } = useToastsContext();

    const intervalId = useRef<number>();

    useEffect(() => {
        const successHandler = (jobs: AsyncJobsResponse) => {
            // show toast message for each finished job
            const finishedJobs = jobs["finishedJobs"];
            if (finishedJobs.length) {
                finishedJobs.forEach((job) => {
                    // show toast message
                    const message = translate(
                        "The bulk operation for %1 %2 has been completed",
                        [translate(job.entityType), `'${job.entityName}'`]
                    );
                    showToast({ severity: "success", message: message });
                });
            }

            // for jobs with event definitions, dispatch relevant event
            const finishedEventJobs = jobs["finishedEventJobs"];
            if (finishedEventJobs.length) {
                finishedEventJobs.forEach((job) => {
                    if (job.event) {
                        // job.event should always exist here
                        const event = new CustomEvent(
                            job.event,
                            {
                                detail: job,
                            }
                        );
                        window.dispatchEvent(event);
                    }
                });
            }

            // stop if no pending jobs
            if (jobs["runningJobs"].length === 0) {
                clearInterval(intervalId.current);
                resetStatus();
            }
        };

        const url = addBaseUrl("/index/get-async-jobs-status?format=script");

        if (shouldCheckAsyncJobsStatus) {
            // poll async jobs status
            intervalId.current = window.setInterval(
                () => {
                    getDataFromKms(url, {}, successHandler, false);
                },
                5000
            );
        }

        return () => {
            clearInterval(intervalId.current);
        };
    }, [resetStatus, shouldCheckAsyncJobsStatus, showToast]);

    // render nothing
    return <span />;
};

export default AsyncJobsChecker;
