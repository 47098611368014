import { MeetingRoom, MeetingRoomProps } from "./MeetingRoom";
import { HeroLayout, HeroLayoutMode } from "@kaltura/mediaspace-shared-layout";
import { MeetingRoomHelper } from "./MeetingRoomHelper";
import { useInterval } from "@kaltura/mediaspace-shared-hooks";
import { useState } from "react";
import { MeetingRoomType } from "./MeetingRoomType";

export interface FullPageMeetingRoomProps extends MeetingRoomProps {
    // Room type, e.g. room entry, channel room, private room, etc.
    type: MeetingRoomType;
    // Room ID, e.g. entry ID, channel ID
    roomId: string;
    // Pass non-empty value to this prop to mark the room as "live" each roomLiveInterval milliseconds,
    // the room will be marked as closed when the session ends.
    // The prop should be used when the current user is a moderator.
    roomLiveInterval?: number;
    // URL to redirect the user to when the session ends.
    backUrl?: string;
}

/**
 * Render a meeting room that takes the whole available space in the page
 */
export const FullPageMeetingRoom = ({
    type,
    roomId,
    roomLiveInterval,
    backUrl,
    ...props
}: FullPageMeetingRoomProps) => {
    const [isSessionAlive, setIsSessionAlive] = useState(true);

    // Keep marking the room as "live" (if requested) while the session didn't end
    useInterval(
        () => MeetingRoomHelper.updateRoomStatus(type, roomId),
        isSessionAlive && roomLiveInterval ? roomLiveInterval : null
    );

    // Mark the room as "closed" and redirect the user back when the session ends
    const handleSessionEnd = async () => {
        setIsSessionAlive(false);

        if (roomLiveInterval) {
            await MeetingRoomHelper.clearRoomStatus(type, roomId);
        }

        if (backUrl) {
            window.location.href = backUrl;
        }
    };

    return (
        <HeroLayout mode={HeroLayoutMode.FullSize} allowBorderRadius={false}>
            <MeetingRoom {...props} onSessionEnd={handleSessionEnd} />
        </HeroLayout>
    );
};
