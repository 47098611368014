import { WebcastEntry } from "@kaltura/mediaspace-shared-types";
import { useContext, useEffect } from "react";
import { useButtonAnalytics, useCurrentTimeForAnimation } from "@kaltura/mediaspace-shared-hooks";
import { getEntryUrl } from "@kaltura/mediaspace-shared-utils";
import { useEmbeddedCncWidgetContext } from "@kaltura/mediaspace-shared-contexts";
import { createRoot } from "react-dom/client";
import { NotificationContent } from "./NotificationContent";
import { Notification, NotificationCloseReason, NotificationType } from "./types";
import { useSidebarLayoutContext } from "@kaltura/ds-react-layouts";
import { useTheme } from "@emotion/react";
import { SidebarButton } from "./sidebar/SidebarButton";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { Config, ConfigContext } from "@kaltura/mediaspace-shared-data-kms-config";

const UPCOMING_TIME_WINDOW_SEC = 5;

export interface NotificationsManagerProps {
    channelId: number;
    sessions: WebcastEntry[];
    currentlyAttendingSessionId?: string;
    notificationTimeoutMS: number;
}

export const NotificationsManager = ({
    sessions = [],
    currentlyAttendingSessionId,
    channelId,
    notificationTimeoutMS = 6000,
}: NotificationsManagerProps) => {
    const now = useCurrentTimeForAnimation(undefined, 1000) / 1000;
    const { cncWidget } = useEmbeddedCncWidgetContext();
    const { setIsOpened, setActiveButtonId } = useSidebarLayoutContext();
    const config: Config = useContext(ConfigContext);
    const theme = useTheme();
    const sendButtonAnalytics = useButtonAnalytics();

    // function to show notification in cnc notifications container
    const showNotification = (notification: Notification) => {
        // in older cnc versions showToast, not exists in such cases do not do anything
        if (!cncWidget?.showToast) {
            return;
        }

        let notificationClosed = false;

        const callback = (containerId: string, toastId: number) => {
            const notificationContainer = document.getElementById(containerId);
            if (!notificationContainer) {
                return;
            }

            const root = createRoot(notificationContainer);

            const onClosed = (options: { toastId: number; reason: NotificationCloseReason }) => {
                if (options.toastId === toastId) {
                    notificationClosed = true;
                    if (options.reason === NotificationCloseReason.xButton) {
                        sendButtonAnalytics(
                            "TV guide - Close notification",
                            ButtonClickAnalyticsType.CLOSE,
                            notification.analyticsEntryId
                        );
                    }
                    root.unmount();
                }
            };

            if (cncWidget.addOnToastClosed) {
                cncWidget.addOnToastClosed(onClosed);
            }

            if (notificationClosed) {
                // notification been closed do not do anything (seems like callback invoked in cnc even after notification is closed)
                return;
            }

            // Render the notification content component
            root.render(
                <NotificationContent theme={theme} notification={notification} uniqueId={toastId} context={config} />
            );
        };

        cncWidget.showToast({
            timeout: notificationTimeoutMS,
            callback,
        });
    };

    // function to remove notification from cnc notifications container
    const closeNotification = (notificationId: number, reason: string) =>
        cncWidget.closeToastById(notificationId, reason);

    const navigateToSession = (session: WebcastEntry, notificationId: number) => {
        closeNotification(notificationId, NotificationCloseReason.navigateToSession);
        document.location.href = getEntryUrl(session.id, channelId, session.name);
    };

    const browseSessions = (notificationId: number) => {
        const isOnMediaPage = currentlyAttendingSessionId ?? false;
        //  open agenda section in sidebar layout only when in media page
        //  in channel page, user already sees the sessions
        if (isOnMediaPage) {
            // open agenda section in sidebar layout
            setActiveButtonId(SidebarButton.id);
            setIsOpened(true);
        }

        closeNotification(notificationId, NotificationCloseReason.browseSessions);
    };

    const prepareNotification = (sessionsToNotifyAbout: WebcastEntry[], gap?: number): Notification => {
        gap = gap ?? -1;
        const isWithGap = gap > 0;
        // show gap notification only if has a gap and only single session to notify about
        if (isWithGap && sessionsToNotifyAbout.length === 1) {
            return {
                type: NotificationType.gap,
                gapInMinutes: gap,
                session: sessionsToNotifyAbout[0],
                analyticsEntryId: currentlyAttendingSessionId,
                onGoToSession: (session: WebcastEntry, notificationId: number) =>
                    navigateToSession(session, notificationId),
                onBrowseSession: browseSessions,
            };
        }

        return sessionsToNotifyAbout.length > 1
            ? {
                  type: NotificationType.many,
                  upcomingSessions: sessionsToNotifyAbout,
                  analyticsEntryId: currentlyAttendingSessionId,
                  onBrowseSessions: browseSessions,
              }
            : {
                  type: NotificationType.single,
                  timeTillStart: UPCOMING_TIME_WINDOW_SEC,
                  session: sessionsToNotifyAbout[0],
                  analyticsEntryId: currentlyAttendingSessionId,
                  onWatch: (session: WebcastEntry, notificationId: number) =>
                      navigateToSession(session, notificationId),
                  onStay: (notificationId: number) => closeNotification(notificationId, NotificationCloseReason.stay),
              };
    };

    const isTimeArrived = (timestamp: number, timeDiff = 0): boolean => Math.floor(timestamp - now) === timeDiff;

    const notifyAboutNextSession = (sessionsAboutToStart: WebcastEntry[], sessionsStartingGap?: number) => {
        const notification = prepareNotification(sessionsAboutToStart, sessionsStartingGap);
        // don't show notification to user
        // if user already in media page for session which is about to start
        if (
            currentlyAttendingSessionId
            && notification.type === NotificationType.single
            && sessionsAboutToStart[0].id === currentlyAttendingSessionId
        ) {
            return;
        }
        showNotification(notification);
    };

    const handleCurrentlyAttendingSession = (
        currentlyAttendingSession: WebcastEntry,
        sessionsAboutToStart: WebcastEntry[]
    ) => {
        const nextSessionStartTime = sessionsAboutToStart[0].schedulingData.start.timestamp;
        const sessionsStartingGap = Math.round((nextSessionStartTime - now) / 60);
        if (isTimeArrived(currentlyAttendingSession.schedulingData.end.timestamp)) {
            // current session just ended
            notifyAboutNextSession(sessionsAboutToStart, sessionsStartingGap);
        }
        else if (!sessionsStartingGap && isTimeArrived(nextSessionStartTime)) {
            // current session not ended yet and another is starting now (if already ended currentlyAttendingSession will be null)
            notifyAboutNextSession(sessionsAboutToStart);
        }
    };

    const handleBrowsingEndedOrFutureSessions = (sessionsAboutToStart: any[]) => {
        const nextSessionStartTime = sessionsAboutToStart[0].schedulingData.start.timestamp;
        const shouldNotifyForSingleSession =
            sessionsAboutToStart.length === 1 && isTimeArrived(nextSessionStartTime, UPCOMING_TIME_WINDOW_SEC);
        const shouldNotifyForMultipleSessions = sessionsAboutToStart.length > 1 && isTimeArrived(nextSessionStartTime);
        if (shouldNotifyForSingleSession || shouldNotifyForMultipleSessions) {
            notifyAboutNextSession(sessionsAboutToStart);
        }
    };

    console.debug("Notification manager attached");

    // Effect to schedule notifications based on the next sessions
    useEffect(() => {
        if (!sessions?.length) {
            console.debug("no entries");
            return;
        }

        const upcomingSessions = sessions.filter((entry) => entry.schedulingData.start.timestamp > now);
        if (!upcomingSessions.length) {
            console.debug("no upcoming entries");
            return;
        }

        const currentLiveEntries = sessions.filter(
            (entry) => entry.schedulingData.start.timestamp <= now && entry.schedulingData.end.timestamp >= now
        );
        const currentlyAttendingSession =
            currentLiveEntries.find((entry) => entry.id === currentlyAttendingSessionId) ?? null;
        const nextSessionStartTime = upcomingSessions[0].schedulingData.start.timestamp;
        const sessionsAboutToStart = upcomingSessions.filter(
            (entry) => entry.schedulingData.start.timestamp === nextSessionStartTime
        );

        // user is currently attending session (user on media page)
        if (currentlyAttendingSession) {
            handleCurrentlyAttendingSession(currentlyAttendingSession, sessionsAboutToStart);
        }
        else {
            // user browsing future sessions, ended sessions or user on channel page (aka sees sessions schedule)
            handleBrowsingEndedOrFutureSessions(sessionsAboutToStart);
        }
    }, [sessions, now, currentlyAttendingSessionId]);

    return null; // don't  render anything
};
