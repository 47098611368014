import { Button, Modal, ModalActions, ModalContent, ModalTitle, Typography } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { HtmlReactParser } from "@kaltura/mediaspace-shared-utils";
import styled from "@emotion/styled";

export interface ConfirmationModalProps {
    /**
     * function to trigger once user approves
     */
    yesCallback?: () => void;

    /**
     * function to trigger once user disapproves
     */
    noCallback?: () => void;

    /**
     * label for the "yes" button, defaults to "ok"
     */
    yesButtonLabel?: string;

    /**
     * label for the "no" button, defaults to "cancel"
     */
    noButtonLabel?: string;

    /**
     * prompt title
     */
    title?: string;

    /**
     * prompt text
     */
    text?: string;

    /**
     * is prompt currently showing
     */
    open: boolean;
}

export type ConfirmationModalData = Omit<ConfirmationModalProps, "open">;

export function ConfirmationModal({
    yesCallback,
    noCallback,
    yesButtonLabel,
    noButtonLabel,
    title,
    text,
    open,
}: ConfirmationModalProps) {
    return (
        <StyledModal
            variant="task"
            open={open}
            classes={{ paper: "confirmation-modal-paper" }}
            className={"kms-ds-confirmation-modal"}
        >
            {!!title && <ModalTitle>{title}</ModalTitle>}
            {!!text && (
                <ModalContent>
                    <Typography variant={"body1"} align={"center"}>
                        {HtmlReactParser(text)}
                    </Typography>
                </ModalContent>
            )}
            <StyledModalActions>
                <Button variant={"borderless"} color={"secondary"} onClick={noCallback}>
                    {noButtonLabel || translate("Cancel")}
                </Button>
                <Button onClick={yesCallback}>{yesButtonLabel || translate("OK")}</Button>
            </StyledModalActions>
        </StyledModal>
    );
}

const StyledModal = styled(Modal)(({ theme }) => ({
    "& .confirmation-modal-paper": {
        width: theme.typography.pxToRem(600),
        height: theme.typography.pxToRem(236),
        [theme.breakpoints.down("sm")]: {
            width: theme.typography.pxToRem(343),
        },
    },
}));

const StyledModalActions = styled(ModalActions)(({ theme }) => ({
    [theme.breakpoints.down("sm")]: {
        padding: theme.spacing(2),
    },
}));
