import styled from "@emotion/styled";
import { Box } from "@mui/material";

export const StyledForm = styled("form")(({ theme }) => ({
    height: "100%",
    borderBottom: "1px solid",
    borderColor: theme.kaltura.palette.tone4,
}));

export const StyledActionsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "end",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    gap: theme.spacing(2),
}));
export const ErrorMessage = styled("div")(({ theme }) => ({
    color: theme.kaltura.palette.danger.main,
    marginTop: theme.spacing(0.5),
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.kaltura.typography.fontWeightRegular,
    lineHeight: theme.typography.pxToRem(18),
    textAlign: "left",
}));
