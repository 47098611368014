import { StyledIllustration } from "@kaltura/mediaspace-shared-ui";

export const DraftIllustration = () => (
    <StyledIllustration
        fileName={"AddNewDraft.svg"}
        width="201"
        height="200"
        viewBox="0 0 201 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="37.4648"
            y="67.6982"
            width="103.704"
            height="78.5185"
            rx="8"
            transform="rotate(-7.20074 37.4648 67.6982)"
            fill="#006EFA"
        />
        <path
            d="M124.94 5.33301C128.397 8.04906 131.829 15.1108 117.903 21.6293C100.496 29.7775 109.385 37.5552 117.903 37.1849C126.422 36.8145 119.385 29.4071 114.94 34.2219C111.385 38.0737 111.73 48.9133 112.348 53.8515"
            className="sTone1"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M125.236 68.3044C130.89 79.3626 123.404 91.3333 112.167 91.3333C100.93 91.3333 93.4859 79.3626 99.0593 68.4273C101.214 64.199 104.122 60.4417 104.81 56.6905C105.883 50.832 106.413 48 112.01 48C118.133 48 118.133 51.2645 119.348 56.6905C120.596 62.2579 124.067 66.0175 125.236 68.3044Z"
            fill="#FFD357"
        />
        <path
            d="M109.65 64C109.65 64 109.102 71.9801 107.698 74.7779C106.294 77.5763 105.125 79.0332 104.071 78.0425C103.121 77.1471 105.007 73.1456 110.037 76.5267C115.067 79.9073 113.195 83.4044 111.558 82.5309C110.607 82.0227 108.428 79.5971 114.716 76.8764C119.162 74.9527 120.741 78.1008 119.337 79.1497C118.18 80.015 115.535 80.1535 114.482 72.2201"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M57.2281 83.9154C57.7999 81.7815 59.9933 80.5152 62.1271 81.0869L78.5156 85.4782C80.6495 86.05 81.9158 88.2433 81.344 90.3772L76.9527 106.766C76.381 108.9 74.1876 110.166 72.0538 109.594L55.6653 105.203C53.5314 104.631 52.2651 102.438 52.8369 100.304L57.2281 83.9154Z"
            fill="white"
        />
        <path
            d="M59.834 93.6918C60.8487 92.994 61.8701 92.3169 62.7893 92.0823C63.7085 91.8478 64.5287 92.0902 64.928 93.1242C65.2054 93.8424 65.2671 94.8964 65.4504 95.7647C65.749 97.1791 66.4795 98.1077 67.6182 97.3789C68.4673 96.8355 69.4618 95.4315 70.1953 95.383C70.8475 95.3399 71.0592 96.382 71.3736 97.1318C71.9726 98.5602 73.117 99.0209 74.5007 98.3904"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M28.2403 57.0733C28.2403 54.8641 30.0312 53.0733 32.2403 53.0733L51.3533 53.0733C53.5624 53.0733 55.3533 54.8641 55.3533 57.0733L55.3533 76.1863C55.3533 78.3954 53.5624 80.1863 51.3533 80.1863L32.2403 80.1863C30.0312 80.1863 28.2403 78.3954 28.2403 76.1863L28.2403 57.0733Z"
            fill="#FF3D23"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M80.4951 114.074C80.4951 109.656 84.0768 106.074 88.4951 106.074L155.458 106.074C159.876 106.074 163.458 109.656 163.458 114.074V156.546C163.458 160.931 159.927 164.499 155.542 164.545L88.5791 165.249C84.1283 165.295 80.4951 161.7 80.4951 157.249V114.074Z"
            fill="#FFD357"
        />
        <path
            d="M93.0879 156.074C100.372 155.827 116.051 155.259 120.495 154.963C124.94 154.667 134.513 154.543 138.834 154.667"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <ellipse cx="122.188" cy="132.682" rx="12" ry="12" fill="white" />
        <path
            d="M127.354 131.815C128.02 132.2 128.02 133.162 127.354 133.547L120.354 137.589C119.687 137.974 118.854 137.493 118.854 136.723L118.854 128.64C118.854 127.87 119.687 127.389 120.354 127.774L127.354 131.815Z"
            fill="#FF9DFF"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M138.418 145.344L135.779 155.162L145.869 154.561L138.418 145.344Z"
            fill="white"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M136.73 151.619L135.784 155.159L139.425 154.946L136.73 151.619Z"
            fill="#333333"
        />
        <ellipse
            cx="183.596"
            cy="116.385"
            rx="5.92593"
            ry="5.92593"
            transform="rotate(51 183.596 116.385)"
            fill="#FFD357"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M179.866 111.78L187.294 120.952L145.861 154.567L138.41 145.351L179.866 111.78Z"
            fill="#FF3D23"
        />
    </StyledIllustration>
);
