import { MenuItem, NestedMenuItem } from "@kaltura/ds-react-components";
import { ChevronRight16Icon, Check24Icon } from "@kaltura/ds-react-icons";
import { KmsTypeLinkLink } from "@kaltura/mediaspace-shared-types";
import { getKmsData } from "@kaltura/mediaspace-shared-utils";

interface ThemeMenuItemProps {
    label: string;
    analyticsValue: string;
    items: KmsTypeLinkLink[];
}

/**
 * Theme Menu Item for the user menu
 */
export const ThemeMenuItem = ({ label, items }: ThemeMenuItemProps) => {
    const handleThemeClick = async (link: KmsTypeLinkLink) => {
        const response = await getKmsData(link.href);
        if (response.success) {
            window.location.reload();
        }
    };

    return (
        <NestedMenuItem
            label={label}
            icon={<ChevronRight16Icon />}
            MenuListProps={{
                autoFocusItem: true,
            }}
        >
            {items.map((item, index) => (
                <MenuItem
                    key={index}
                    component="a"
                    href={item.href}
                    onClick={() => handleThemeClick(item)}
                    icon={item.attributes?.selected ? <Check24Icon /> : undefined}
                >
                    {item.label}
                </MenuItem>
            ))}
        </NestedMenuItem>
    );
};

export default ThemeMenuItem;
