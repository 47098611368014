import { useState } from "react";
import { KmsTypePublishUserCategories, KmsTypePublishCategories } from "@kaltura/mediaspace-shared-types";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { PendingAlert } from "../pending-alert/PendingAlert";
import { CategorySearchInput } from "../category-search/CategorySearchInput";
import { GalleriesTabFormContainer } from "./GalleriesTabFormContainer";

export interface GalleriesTabProps {
    entryIds: string[];
    galleries: KmsTypePublishCategories;
    userCategories: KmsTypePublishUserCategories;
    onCategoryChange: (categoryId: number, checked: boolean) => void;
    onPendingClick: () => void;
    isBulk?: boolean;
}

/**
 * publish modal galleries tab
 */
export function GalleriesTab(props: GalleriesTabProps) {
    const { entryIds, galleries, onPendingClick, isBulk } = props;

    const [keyword, setKeyword] = useState("");

    const handleSearch = (keyword: string) => {
        setKeyword(keyword);
    };

    const handleClearSearch = () => {
        setKeyword("");
    };

    return (
        <>
            <PendingAlert entryIds={entryIds} galleries={galleries} onPendingClick={onPendingClick} isBulk={isBulk} />

            <CategorySearchInput
                placeholder={translate("Search galleries")}
                ariaLabel={translate("Enter text to search galleries")}
                onSearch={handleSearch}
                onResetSearch={handleClearSearch}
            />

            <GalleriesTabFormContainer {...props} keyword={keyword} isBulk={isBulk} />
        </>
    );
}
