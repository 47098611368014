import { KmsTypePublishCategory } from "@kaltura/mediaspace-shared-types";
import { CategoryType, galleryLabel } from "../../../../../../utils";
import { CategoryCheckbox, CategoryCheckboxProps } from "./../category-checkbox/CategoryCheckbox";

export interface GalleryCheckboxProps extends Omit<CategoryCheckboxProps, "category" | "tooltipText" | "categoryType"> {
    gallery?: KmsTypePublishCategory;
    items: { [key: string]: GalleryCheckboxProps };
    isBulk?: boolean;
}

/**
 * gallery checkbox - recursively render checkbox and its items
 */
export const GalleryCheckbox = (props: GalleryCheckboxProps) => {
    const { isChecked, onCategoryChange, gallery, disabled = false, depth = 1, items, isBulk } = props;

    const childItems = Object.entries(items);

    return (
        <>
            {gallery && (
                <CategoryCheckbox
                    category={gallery}
                    categoryType={CategoryType.gallery}
                    tooltipText={galleryLabel(gallery)}
                    depth={depth}
                    disabled={disabled}
                    isChecked={isChecked}
                    onCategoryChange={onCategoryChange}
                    isBulk={isBulk}
                />
            )}
            <>
                {childItems.map(([id, item]) => {
                    return (
                        <div key={id}>
                            <GalleryCheckbox {...props} {...item} depth={gallery ? depth + 1 : depth} />
                        </div>
                    );
                })}
            </>
        </>
    );
};
