import { useCallback, useEffect, useState } from "react";

/**
 * Tracks width/height of a DOM element.
 *
 * The element must be visible (not display:none) to be able to calculate the size.
 *
 * Note that there's one frame until getting the ref to the element,
 * so the hook will return undefined instead of width and height before getting the ref.
 *
 * The "elementRef" prop of the returned object should be used as element's "ref" property. e.g.
 * const {elementRef, width, height} = useElementSizeObserver();
 * return <div ref={elementRef}>Some content</div>;
 */
export const useElementSizeObserver = <T extends HTMLElement = HTMLDivElement>() => {
    const [element, setElement] = useState<T | null>(null);

    const [width, setWidth] = useState<number | undefined>(undefined);
    const [height, setHeight] = useState<number | undefined>(undefined);
    const [totalLines, setTotalLines] = useState<number | undefined>(undefined);

    const updateSize = useCallback(
        (element: T) => {
            setWidth(element.offsetWidth);
            setHeight(element.offsetHeight);
            // Calculate the number of lines of text within the element.
            // We use clientHeight instead of offsetHeight for the following reasons:
            // 1. clientHeight includes the height of the element's content and padding but excludes the borders,
            //    providing a more accurate measurement of the content height.
            // 2. clientHeight ensures that the height measurement is focused solely on the content and padding,
            //    leading to a more precise line count.
            // 3. clientHeight does not include the height of horizontal scrollbars, which can be present if the content overflows.
            // 4. The lineHeight property from getComputedStyle represents the height of a single line of text, excluding borders.
            //    Using clientHeight ensures that we are comparing like with like, leading to a more accurate calculation of the number of lines.
            // 5. The measured element is not always Typography, it could be anything. So the computed lineHeight could be '0px', 'normal', etc.
            //    We check for 0 and NaN to be on the safe side.
            const computedStyle = getComputedStyle(element);
            const lineHeight = parseFloat(computedStyle.lineHeight);
            if (isNaN(lineHeight) || lineHeight === 0) {
                // If element's lineHeight is '0px', 'normal', etc., we can't calculate the number of lines.
                // Currently, in such cases totalLines will be undefined.
                // If this is a common case, we might want to handle it differently and set a fallback value to it
                return;
            }
            const numberOfLines = Math.round(element.clientHeight / lineHeight);
            setTotalLines(numberOfLines);
        },
        [setWidth, setHeight, setTotalLines]
    );

    const elementRef = useCallback(
        (element: T | null) => {
            if (element) {
                updateSize(element);
            }
            setElement(element);
        },
        [updateSize, setElement]
    );

    useEffect(() => {
        if (!element) {
            return;
        }

        updateSize(element);

        const { ResizeObserver } = window as any;
        if (ResizeObserver) {
            // The timeout is to prevent the recursive resize observer error.
            // See https://developer.mozilla.org/en-US/docs/Web/API/ResizeObserver#observation_errors
            const observer = new ResizeObserver(() => setTimeout(() => updateSize(element)));
            observer.observe(element);
            return () => {
                observer.unobserve(element);
            };
        }

        return;
    }, [element, updateSize]);

    return { elementRef, width, height, totalLines };
};
