import { PublishMenuItems, PublishMenuItemsProps } from "../../../publish/publish-menu/PublishMenuItems";
import { Button, Menu } from "@kaltura/ds-react-components";
import { Globe24Icon, Link24Icon, Lock24Icon } from "@kaltura/ds-react-icons";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { useState, MouseEvent, ReactElement } from "react";
import { ButtonClickAnalyticsType, PublishStatus } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";

export interface PublishMenuButtonProps extends PublishMenuItemsProps {
    label?: string;
    icon?: ReactElement;
    buttonAnalyticsActionName?: string;
    isLoading?: boolean;
}

/**
 * publish menu button
 * used in:
 * my-media bulk action (desktop), upload media bulk action,
 * upload media single entry action, express recorder single entry action (see AsyncPublishMenuButton)
 */
export function PublishMenuButton(props: PublishMenuButtonProps) {
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

    const { label, icon, buttonAnalyticsActionName, entryIds, isLoading = false, ...rest } = props;

    const entryId = entryIds.length === 1 ? entryIds[0] : undefined;

    const sendButtonAnalytics = useButtonAnalytics();

    const close = () => setAnchorEl(null);

    const getPublishIcon = (publishStatus?: PublishStatus) => {
        switch (publishStatus) {
            case PublishStatus.UNLISTED:
                return <Link24Icon />;
            case PublishStatus.PRIVATE:
                return <Lock24Icon />;
            default:
                return <Globe24Icon />;
        }
    };

    const getPublishLabel = (publishStatus?: PublishStatus) => {
        switch (publishStatus) {
            case PublishStatus.UNLISTED:
                return translate("Unlisted");
            case PublishStatus.PRIVATE:
                return translate("Private");
            default:
                return translate("Published");
        }
    };

    const handleClick = (event: MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        buttonAnalyticsActionName
            && sendButtonAnalytics(buttonAnalyticsActionName, ButtonClickAnalyticsType.CHOOSE, entryId);
    };

    return (
        <>
            <Button
                variant={"borderless"}
                color={"secondary"}
                onClick={handleClick}
                startIcon={icon || getPublishIcon(rest.publishStatus)}
                loading={isLoading}
                disabled={isLoading}
            >
                {label || getPublishLabel(rest.publishStatus)}
            </Button>
            <Menu id={"publish"} anchorEl={anchorEl} open={!!anchorEl} onClose={close}>
                <PublishMenuItems entryIds={entryIds} onCloseMenu={close} {...rest} />
            </Menu>
        </>
    );
}

export default PublishMenuButton;
