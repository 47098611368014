import UploadService from "./lib/upload/UploadService";
import {
    FileMetaData,
    UploadFileInfo,
    UploadProps,
    StatusesMapper,
    UploadStatus,
    UploadServiceHandler,
    numberOfMaxChunksForSingleFile,
} from "./lib/upload/UploadServiceProps";

export * from "./lib/upload/UploadMediaProps";
export { StatusesMapper, UploadStatus, numberOfMaxChunksForSingleFile };
export type { FileMetaData, UploadFileInfo, UploadProps, UploadServiceHandler };
export default UploadService;
