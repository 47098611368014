import { ComponentType, useState } from "react";
import styled from "@emotion/styled";
import Slider, { Settings } from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import clsx from "clsx";
import { getComponentFromPath, translate, useMediaQuery } from "@kaltura/mediaspace-shared-utils";
import { ChevronLeft24Icon, ChevronRight24Icon } from "@kaltura/ds-react-icons";
import { Box, composeClasses, systemWidth, useTheme } from "@kaltura/mediaspace-shared-styled";
import { CarouselClasses, getCarouselClass } from "./carouselClasses";
import { CustomArrow } from "./custom-arrow/CustomArrow";
import ItemContainer from "./item-container/ItemContainer";

type ItemProps<ItemT> = {
    item: ItemT;
    currentCardsNumberInSlides: 2 | 3 | 4 | 5;
    minPossibleCardsNumberInSlides: 2 | 3 | 4 | 5;
    onResize?: (height: number) => void;
};

type Props<ItemT, PropsT> = {
    active?: boolean;
    items: ItemT[];
    currentCardsNumberInSlides: 2 | 3 | 4 | 5; // amount of slides on the screen for large devices ("xl" and "lg")
    mdCardsNumberInSlides?: number; // amount of slides on the screen for medium devices ("md")
    smCardsNumberInSlides?: number; // amount of slides on the screen for small devices ("sm")
    xsCardsNumberInSlides?: number; // amount of slides on the screen for xs devices
    minPossibleCardsNumberInSlides?: 2 | 3 | 4 | 5; // the smallest number of slides this carousel shows
    className?: string;
    itemComponent: ComponentType<ItemProps<ItemT> & PropsT>;
    itemProps?: PropsT | ((item: ItemT, index: number) => PropsT);
    classes?: Partial<CarouselClasses>;
    container?: string; // analytics action name depends on the container component of the carousel (e.g: "News" / "Playlist" ...)
    fullScreenWidth?: boolean;
    ariaLabelledById?: string;
};

const useUtilityClasses = (styleProps: Partial<Props<any, any>>) => {
    const { classes } = styleProps;

    const slots = {
        root: ["root"],
        item: ["item"],
        arrow: ["arrow"],
    };

    return composeClasses(slots, getCarouselClass, classes);
};

export const slickClasses = {
    slide: "slick-slide",
    list: "slick-list",
    arrow: "slick-arrow",
    next: "slick-next",
    prev: "slick-prev",
    disabled: "slick-disabled",
    track: "slick-track",
    regular: "regular",
    oneSlide: "one-slide",
};

const StyledSlider = styled(
    Slider,
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return propName !== "fullScreenWidth" && propName !== "imageHeight" && propName !== "gap";
        },
    }
)<{ fullScreenWidth?: boolean; imageHeight?: number; gap: number }>(({ theme, fullScreenWidth, imageHeight, gap }) => [
    {
        // styles applied to root element
        // desktops will have a scroll bar, and are less relevant in these sizes
        ["max-device-size: " + (theme.breakpoints.values.md - 0.05)]: {
            overflowX: "hidden",
            msOverflowX: "hidden",
        },
        // styles applied to slide
        [`.${slickClasses.slide}`]: {
            width: 276,
        },
        // styles applied to slide list
        [`.${slickClasses.list}`]: {
            marginRight: theme.spacing(-gap),
        },
        // styles applied to arrow
        [`.${slickClasses.arrow}`]: {
            zIndex: 10,
            height: "auto",
            ...(
                !!imageHeight && {
                    top: imageHeight / 2,
                }
            ),
        },
        // styles applied to next arrow
        [`.${slickClasses.next}`]: {
            right: fullScreenWidth ? theme.spacing(3) : -20,
        },
        // styles applied to prev arrow
        [`.${slickClasses.prev}`]: {
            left: fullScreenWidth ? theme.spacing(1) : -36,
        },
        // styles applied to arrow (hide third party arrow)
        [`.${slickClasses.arrow}:before`]: {
            content: "none",
        },
        [theme.breakpoints.up("lg")]: {
            // styles applied to next arrow
            [`.${slickClasses.next}`]: {
                right: fullScreenWidth ? theme.spacing(3) : -20,
            },
            // styles applied to prev arrow
            [`.${slickClasses.prev}`]: {
                left: fullScreenWidth ? theme.spacing(1) : -48,
            },
        },
    },
    fullScreenWidth && {
        overflow: "hidden",
        [`.${slickClasses.arrow}`]: {
            zIndex: 2,
        },
        [`.${slickClasses.track}`]: {
            left: theme.spacing(0),
        },
    },
    systemWidth({ theme }),
]);

const StyledUnSlider = styled(
    Box,
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return propName !== "fullScreenWidth";
        },
    }
)<{ fullScreenWidth?: boolean }>(({ theme, fullScreenWidth }) => [
    fullScreenWidth && {
        overflow: "hidden",
    },
    {
        whiteSpace: "nowrap",
        overflowX: "auto",
        msOverflowX: "auto",
        scrollbarWidth: "none",
        "& .kms-ds-carousel-item": {
            display: "inline-block",
            verticalAlign: "top",
        },
        [`&::-webkit-scrollbar`]: {
            display: "none",
        },

        // desktops will have a scroll bar, and are less relevant in these sizes
        ["max-device-size: " + (theme.breakpoints.values.md - 0.05)]: {
            overflowX: "hidden",
            msOverflowX: "hidden",
        },
    },
]);

const StyledUnSliderInner = styled(
    Box,
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return propName !== "fullScreenWidth" && propName !== "slidesCount" && propName !== "gap";
        },
    }
)<{ fullScreenWidth?: boolean; slidesCount: number; gap: number }>(({ theme, fullScreenWidth, slidesCount, gap }) => [
    !fullScreenWidth && systemWidth({ theme }),
    {
        // inline display is required for scrolling the carousel track on mobile
        display: "inline-flex",
        gap: theme.spacing(gap),
        [theme.breakpoints.up("md")]: {
            // block display is required for applying system width correctly on desktop
            display: "flex",
            justifyContent: "center",
            "& .kms-ds-carousel-item": {
                width: `calc((100% - ${theme.spacing(gap * (slidesCount - 1))}) / ${slidesCount})`,
            },
        },
    },
]);

/**
 * Generic component for carousel. The component handle carousels of 2-4 cards.
 */
export const Carousel = <ItemT, PropsT>({
    items,
    currentCardsNumberInSlides = 4,
    mdCardsNumberInSlides = Math.min(currentCardsNumberInSlides, 3),
    smCardsNumberInSlides = Math.min(currentCardsNumberInSlides, 2),
    xsCardsNumberInSlides = Math.min(currentCardsNumberInSlides, 1),
    minPossibleCardsNumberInSlides = currentCardsNumberInSlides,
    className,
    itemProps,
    itemComponent,
    container,
    fullScreenWidth = false,
    ariaLabelledById,
    ...rest
}: Props<ItemT, PropsT>) => {
    const ItemComponent = getComponentFromPath(itemComponent);
    const classes = useUtilityClasses(rest);

    const theme = useTheme();
    const [imageHeight, setImageHeight] = useState(0);

    const isDownMd = useMediaQuery(theme.breakpoints.down("md"));
    const isDownLg = useMediaQuery(theme.breakpoints.down("lg"));
    const isDownSm = useMediaQuery(theme.breakpoints.down("sm"));
    const isArrowsInside = isDownMd || fullScreenWidth;

    const gap = isDownLg ? 2 : 4;

    const finalSlidesCount = (() => {
        if (isDownSm) return xsCardsNumberInSlides;
        if (isDownMd) return smCardsNumberInSlides;
        if (isDownLg) return mdCardsNumberInSlides;
        return currentCardsNumberInSlides;
    })();

    const settings: Settings = {
        slidesToShow: finalSlidesCount,
        slidesToScroll: finalSlidesCount,
        infinite: true,
        nextArrow: (
            <CustomArrow
                iconClassName={classes.arrow}
                icon={<ChevronRight24Icon />}
                ariaLabel={translate("next slide arrow")}
                position={"Right"}
                container={container}
                fullScreenWidth={isArrowsInside}
            />
        ),
        prevArrow: (
            <CustomArrow
                iconClassName={classes.arrow}
                icon={<ChevronLeft24Icon />}
                ariaLabel={translate("previous slide arrow")}
                position={"Left"}
                container={container}
                fullScreenWidth={isArrowsInside}
            />
        ),
    };

    const onThumbnailResize = (height: number) => {
        setImageHeight(height);
    };

    const unslick = items.length <= finalSlidesCount;

    const renderedItems = items.map((item, index) => {
        return (
            <ItemContainer
                key={index}
                className={classes.item}
                gap={unslick ? 0 : gap}
                role={"group"}
                aria-roledescription={"slide"}
            >
                <ItemComponent
                    item={item}
                    currentCardsNumberInSlides={currentCardsNumberInSlides}
                    minPossibleCardsNumberInSlides={minPossibleCardsNumberInSlides}
                    onThumbnailResize={onThumbnailResize}
                    {...(typeof itemProps === "function"
                        ? (itemProps as (item: ItemT, index: number) => PropsT)(item, index)
                        : itemProps!)}
                />
            </ItemContainer>
        );
    });

    return (
        <>
            {!unslick && (
                <Box role={"group"} aria-roledescription={"carousel"} aria-labelledby={ariaLabelledById}>
                    <StyledSlider
                        className={clsx(className, classes.root)}
                        // react-slider can't update properly when changing the slides count, so re-render it completely
                        key={finalSlidesCount}
                        fullScreenWidth={isArrowsInside}
                        imageHeight={imageHeight}
                        gap={gap}
                        {...settings}
                    >
                        {renderedItems}
                    </StyledSlider>
                </Box>
            )}
            {unslick && (
                <Box role={"group"} aria-labelledby={ariaLabelledById}>
                    <StyledUnSlider
                        className={clsx(className, classes.root, slickClasses.regular)}
                        fullScreenWidth={isArrowsInside}
                    >
                        <StyledUnSliderInner fullScreenWidth={isArrowsInside} slidesCount={finalSlidesCount} gap={gap}>
                            {renderedItems}
                        </StyledUnSliderInner>
                    </StyledUnSlider>
                </Box>
            )}
        </>
    );
};
