import { styled } from "@mui/material";
import { useEffect, useRef } from "react";
import { useEventHandler } from "@kaltura/mediaspace-shared-hooks";

const FullSizeIframe = styled("iframe")({
    width: "100%",
    height: "100%",
    border: 0,
});

export interface MeetingRoomProps {
    // The URL of the iframe that loads the room contents
    roomUrl: string;
    onSessionEnd?: (endedForAll: boolean) => void;
}

/**
 * Generic component for rendering a meeting room and handling its post messages.
 */
export const MeetingRoom = ({ roomUrl, onSessionEnd }: MeetingRoomProps) => {
    const iframeRef = useRef<HTMLIFrameElement>(null);

    // Notify the KME iframe when the main window gains or loses the focus
    useEffect(() => {
        const onFocusChange = (focused: boolean) => {
            iframeRef.current?.contentWindow?.postMessage(
                {
                    eventType: "tab-focus-changed",
                    inFocus: focused,
                },
                "*"
            );
        };

        const onFocus = () => onFocusChange(true);
        const onBlur = () => onFocusChange(false);

        window.addEventListener("focus", onFocus);
        window.addEventListener("blur", onBlur);
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener("blur", onBlur);
        };
    }, []);

    // Handle post messages from the KME iframe
    const onSessionEndMemoized = useEventHandler(onSessionEnd);
    useEffect(() => {
        const handler = (e: MessageEvent) => {
            if (e.data.type === "session-end") {
                onSessionEndMemoized(e.data.originEvent === "instructorEndedSessionAll");
            }
        };

        window.addEventListener("message", handler);

        return () => {
            window.removeEventListener("message", handler);
        };
    }, [onSessionEndMemoized]);

    return (
        <FullSizeIframe
            allow="microphone *; camera *; speakers *; usermedia *; autoplay *; fullscreen *; display-capture *;"
            allowFullScreen={true}
            src={roomUrl}
            ref={iframeRef}
        />
    );
};
