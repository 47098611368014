import React, { useEffect, useState } from "react";
import { SelectedPlaylists } from "../../playlists-modal/PlaylistsModal";
import { BaseInput } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import {
    StyledListContainer,
    StyledSearch24Icon,
} from "@kaltura/mediaspace-shared-entry/media-actions/items/add-to-playlist/modal/StyledComponents";
import {
    filterPlaylists,
    onInputKeyDown,
} from "@kaltura/mediaspace-shared-entry/media-actions/items/add-to-playlist/helpers";
import ListItem from "@kaltura/mediaspace-shared-entry/media-actions/items/add-to-playlist/modal/list-item/ListItem";
import CreatePlaylist from "@kaltura/mediaspace-shared-entry/media-actions/items/add-to-playlist/modal/create-playlist/CreatePlaylist";
import { uniqueId } from "lodash";
import {
    ChangesType,
    PlaylistType,
} from "@kaltura/mediaspace-shared-entry/media-actions/items/add-to-playlist/modal/AddToPlaylistModal";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";

type Playlist = {
    id: string;
    name: string;
    playlistContent: string;
};

type ExtraPlaylist = {
    key: string;
    label: string;
    isChecked: boolean;
};

export type GetPlaylistsProps = {
    allowCreate: boolean;
    playlists: Playlist[];
    extraPlaylists: ExtraPlaylist[];
};

export interface PlaylistsFormProps {
    /**
     * data fetching action
     */
    fetchData: () => GetPlaylistsProps;

    /**
     * list of entry ids to act on
     * (set by host)
     */
    entryIds: string[];

    /**
     * set playlist data dispatcher
     */
    setSelectedPlaylists: (selectedPlaylists: SelectedPlaylists) => void;

    /**
     * list of modified checks
     */
    changes: ChangesType;

    /**
     * set modified checks list dispatcher
     */
    setChanges: (changes: ChangesType) => void;
}

/**
 * regular playlists objects are different from extraplaylists objects,
 * so they are being formatted to a common object
 */
const formatPlaylistObject = (playlists: (Playlist | ExtraPlaylist)[], entryIds: string[]) => {
    return playlists.map((item: Playlist | ExtraPlaylist): PlaylistType => {
        const id = "key" in item ? item.key : item.id;
        const name = "label" in item ? item.label : item.name;
        let isChecked = false;
        if (entryIds.length !== 1) {
            isChecked = false;
        }
        else {
            if ("playlistContent" in item) {
                isChecked = item.playlistContent.includes(entryIds[0]);
            }
            else {
                isChecked = item.isChecked;
            }
        }

        return { id, name, isContainingEntry: isChecked };
    });
};

const PlaylistsForm = (props: PlaylistsFormProps) => {
    const { fetchData, setSelectedPlaylists, entryIds, changes, setChanges } = props;

    const { allowCreate, playlists, extraPlaylists } = fetchData();

    const formattedPlaylists = formatPlaylistObject(playlists, entryIds);
    const formattedExtraPlaylists = formatPlaylistObject(extraPlaylists, entryIds);

    const [originalPlaylists, setOriginalPlaylists] = useState<PlaylistType[]>(formattedPlaylists);
    const [additionalPlaylists, setAdditionalPlaylists] = useState<PlaylistType[]>(formattedExtraPlaylists);
    const [newPlaylists, setNewPlaylists] = useState<PlaylistType[]>([]);
    const [playlistsToShow, setPlaylistsToShow] = useState<PlaylistType[]>([]);
    const [searchTerm, setSearchTerm] = useState<string>("");

    const handleSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.target.value);
    };

    const sendButtonAnalytics = useButtonAnalytics();
    const updateSelection = (id: string, isChecked: boolean, newPlaylist?: PlaylistType) => {
        const changesCopy = { ...changes };
        if (id in changesCopy) {
            delete changesCopy[id];
        }
        else {
            changesCopy[id] = isChecked;
        }
        // search for the updated checkbox in playlists list
        const originalIndex = originalPlaylists.findIndex((item) => item.id === id);
        if (originalIndex >= 0) {
            originalPlaylists[originalIndex].isContainingEntry = isChecked;
            setOriginalPlaylists(originalPlaylists);
        }
        else {
            // search for the updated checkbox in extra playlists list, only if wasn't found in playlists list
            const additionalIndex = additionalPlaylists.findIndex((item) => item.id === id);
            if (additionalIndex >= 0) {
                additionalPlaylists[additionalIndex].isContainingEntry = isChecked;
                setAdditionalPlaylists(additionalPlaylists);
            }
            else {
                // search for the updated checkbox in new playlists list, only if wasn't found in playlists & extra playlists lists
                const newIndex = newPlaylists.findIndex((item) => item.id === id);
                if (newIndex >= 0) {
                    // update existing new playlists status
                    newPlaylists[newIndex].isContainingEntry = isChecked;
                }
                else if (newPlaylist) {
                    // new created playlist is added to the list of new playlists
                    // with a default checked status
                    newPlaylists[newPlaylists.length] = newPlaylist;
                }
                setNewPlaylists(newPlaylists);
            }
        }

        setSelectedPlaylists({
            selected: originalPlaylists.filter((playlist) => playlist.isContainingEntry).map((item) => item.id),
            extra: additionalPlaylists.filter((playlist) => playlist.isContainingEntry).map((item) => item.id),
            newPlaylists: newPlaylists.filter((playlist) => playlist.isContainingEntry).map((item) => item.name),
        });
        setChanges(changesCopy);
        setPlaylistsToShow(filterPlaylists(originalPlaylists, additionalPlaylists, newPlaylists, searchTerm));
        sendButtonAnalytics(
            `My-Media bulk actions - Add to playlist - Playlist ${isChecked ? "Checked" : "Un-Checked"}`,
            ButtonClickAnalyticsType.CHOOSE
        );
    };

    const handleNewPlaylist = (name: string) => {
        const newPlaylist: PlaylistType = {
            name,
            id: uniqueId(),
            isContainingEntry: true,
            isNew: true,
        };
        setNewPlaylists([newPlaylist, ...newPlaylists]);
        setPlaylistsToShow([newPlaylist, ...playlistsToShow]);
        // new created playlist should automatically be checked
        updateSelection(newPlaylist.id, true, newPlaylist);
    };

    const handleFocus = () => {
        sendButtonAnalytics("bulk actions - Add to playlist - Search", ButtonClickAnalyticsType.SEARCH);
    };

    useEffect(() => {
        setPlaylistsToShow([...formattedPlaylists, ...formattedExtraPlaylists]);
    }, []);

    useEffect(() => {
        setPlaylistsToShow(filterPlaylists(originalPlaylists, additionalPlaylists, newPlaylists, searchTerm));
    }, [searchTerm]);

    return (
        <>
            <BaseInput
                placeholder={translate("Search playlist")}
                startAdornment={<StyledSearch24Icon />}
                fullWidth={true}
                onChange={handleSearch}
                onKeyDown={onInputKeyDown}
                onFocus={handleFocus}
            />

            {playlistsToShow.length > 0 && (
                <StyledListContainer>
                    {playlistsToShow.map((playlist: PlaylistType) => (
                        <ListItem
                            key={playlist.id}
                            name={playlist.name}
                            id={playlist.id}
                            isAdded={entryIds.length === 1 || playlist.isNew ? playlist.isContainingEntry : undefined}
                            onChange={updateSelection}
                        />
                    ))}
                </StyledListContainer>
            )}

            {allowCreate && <CreatePlaylist onNew={handleNewPlaylist} />}
        </>
    );
};

export default PlaylistsForm;
