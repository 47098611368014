import Thumbnail from "./thumbnail/Thumbnail";
import NameAndStatus from "./name-and-status/NameAndStatus";
import { StyledAccordion, StyledAccordionSummary, StyledFileRow } from "./StyleComponents";
import RowActions from "./row-actions/RowActions";
import { useMemo, useState } from "react";
import AccordionDetails from "@mui/material/AccordionDetails";
import { EditForm } from "../../edit-form/EditForm";
import { SaveEditFormResponse } from "../../edit-form/SaveEditFormResponse";
import { MediaUploadFileInfo } from "@kaltura/mediaspace-shared-upload";
import { EditFormElement } from "../../edit-form/EditFormElement";
import { KmsTypeComponent } from "@kaltura/mediaspace-shared-types";

export interface FileRowProps {
    /**
     * info to show
     */
    fileInfo: MediaUploadFileInfo;

    /**
     * is edit panel currently open
     */
    isOpen: boolean;

    /**
     * should edit panel close
     */
    shouldClose: boolean;

    /**
     * list of form elements to show on edit form
     */
    editForm: EditFormElement[];

    /**
     * list of components to show on edit form
     */
    editFormComponents?: KmsTypeComponent[];
    /**
     * callback for cancel file upload button
     * @param uploadTokenId
     */
    onCancel: (uploadTokenId: string) => void;

    /**
     * callback for edit button
     * @param uploadTokenId
     */
    onEdit: (uploadTokenId: string) => void;

    /**
     * callback for edit submit button
     */
    onSubmit: (data: SaveEditFormResponse) => void;

    /**
     * callback for "remove from list" button
     * @param uploadTokenId
     */
    onRemove: (uploadTokenId: string) => void;

    /**
     * callback for closing edit form
     */
    onClose: () => void;

    /**
     * used to omit the edit button from the actions row
     */
    showEditButton?: boolean;

    /**
     * used to omit the remove button from the actions row
     */
    showRemoveButton?: boolean;

    /**
     * used to remount each single entry publish action menu
     * to refresh its data after bulk updates
     */
    bulkUpdatesCount?: number;

    /**
     * boolean flag to indicate publish is enabled
     */
    publishEnabled?: boolean;
}

const FileRow = (props: FileRowProps) => {
    const {
        fileInfo,
        isOpen,
        shouldClose,
        editForm,
        editFormComponents,
        onSubmit,
        onClose,
        showEditButton = true,
        showRemoveButton = true,
        bulkUpdatesCount = 0,
        ...rest
    } = props;
    // used to set the single entry publish action idle
    // publish props are refreshed asynchronously after a successful edit
    // so publish is idle while waiting for edit submit response
    const [isEditProcessing, setIsEditProcessing] = useState(false);
    // used to remount the single entry publish action after
    // entry edit finished - to refresh the publish menu props by it
    const [editCount, setEditCount] = useState(0);

    const actions = useMemo(
        () => (
            <RowActions
                {...rest}
                entryId={fileInfo.entry?.id}
                tokenId={fileInfo.fileMetaData.tokenId}
                status={fileInfo.fileMetaData.status}
                entryName={fileInfo.entry?.name}
                isOpen={isOpen}
                showEditButton={showEditButton}
                showRemoveButton={showRemoveButton}
                isEditProcessing={isEditProcessing}
                publishRefreshKey={bulkUpdatesCount + editCount}
            />
        ),
        [
            fileInfo.entry?.id,
            fileInfo.fileMetaData.status,
            fileInfo.fileMetaData.tokenId,
            rest,
            isOpen,
            isEditProcessing,
            bulkUpdatesCount,
            editCount,
        ]
    );

    const entryId = fileInfo.entry?.id || "";
    const entryName = fileInfo.entry?.name || fileInfo.file?.name || "";

    const formData = Object.assign(
        {
            name: entryName,
            description: fileInfo.entry?.description || "",
            tags: fileInfo.entry?.tags || "",
        },
        fileInfo.entryMetadata || {}
    );

    const handleSubmit = (data: SaveEditFormResponse) => {
        onSubmit?.(data);
        setIsEditProcessing(false);
        setEditCount((prev) => prev + 1);
    };

    return (
        <StyledAccordion TransitionProps={{ unmountOnExit: true }} disableGutters elevation={0} expanded={isOpen}>
            <StyledAccordionSummary id={`${entryId}-row`} aria-controls={`${entryId}-edit`}>
                <StyledFileRow isOpen={isOpen} className={"kms-ds-upload-media-file-row"}>
                    <Thumbnail file={fileInfo} />
                    <NameAndStatus name={entryName} metadata={fileInfo.fileMetaData} />
                    {actions}
                </StyledFileRow>
            </StyledAccordionSummary>
            <AccordionDetails>
                <EditForm
                    entryIds={[entryId]}
                    onSubmitStarted={() => setIsEditProcessing(true)}
                    onSubmit={handleSubmit}
                    formData={formData}
                    fieldsStructure={editForm}
                    components={editFormComponents}
                    shouldClose={shouldClose}
                    onClose={onClose}
                />
            </AccordionDetails>
        </StyledAccordion>
    );
};

export default FileRow;
