import { NestedMenuItem } from "@kaltura/ds-react-components";
import { ChevronRight16Icon } from "@kaltura/ds-react-icons";
import { PublishMenuItems, PublishMenuItemsProps } from "../../../publish/publish-menu/PublishMenuItems";

export interface PublishMenuItemProps extends PublishMenuItemsProps {
    label: string;
}

/**
 * publish menu item
 * used in:
 * media page action, my-media single entry action, my-media bulk action (mobile)
 */
export function PublishMenuItem(props: PublishMenuItemProps) {
    const { label, autoFocus, ...rest } = props;

    return (
        <NestedMenuItem key={"publish"} label={label} icon={<ChevronRight16Icon />} autoFocus={autoFocus}>
            <PublishMenuItems {...rest} />
        </NestedMenuItem>
    );
}

export default PublishMenuItem;
