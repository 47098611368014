import { ButtonClickAnalyticsType, EventPlatformEntry, TimeDisplay } from "@kaltura/mediaspace-shared-types";
import {
    durationToHuman,
    formatEntryThumbnailUrl,
    stripTags,
    translate,
    translateTime,
    useMediaQuery,
    WebcastHelper,
    WebcastStatus,
} from "@kaltura/mediaspace-shared-utils";
import styled, { CSSObject } from "@emotion/styled";
import { alpha, Skeleton, useTheme } from "@kaltura/mediaspace-shared-styled";
import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import {
    Data,
    DataContainer,
    ItemDetailsWrapper,
    ItemLink,
    StatusTag,
    StyledMediaImage,
    StyledThumbnail,
    StyledTruncatedDescription,
    StyledTruncatedName,
} from "./CarouselItemStyledComponents";
import { useButtonAnalytics, useElementSizeObserver, useEntryUrl } from "@kaltura/mediaspace-shared-hooks";
import { VisibleFocusStyle } from "@kaltura/ds-react-theme";
import { useEffect } from "react";

export interface CarouselItemProps {
    item: EventPlatformEntry;
    className?: string;
    currentCardsNumberInSlides: 2 | 3 | 4 | 5;
    minPossibleCardsNumberInSlides: 2 | 3 | 4 | 5;
    // render the item without link to the entry (make it not clickable)
    noLink?: boolean;
    categoryId?: number;
    playlistId?: string;
    tabIndex?: number;
    onThumbnailResize?: (height: number) => void;
}

const sharedStyles: CSSObject = {
    display: "inline-block",
    width: "100%",
    height: "auto",
    position: "relative",
};

const StyledItemLink = styled(ItemLink)(({ theme }) => ({
    ...sharedStyles,
    "&:focus-visible": VisibleFocusStyle,
}));

const StyledDiv = styled.div(({ theme }) => ({
    ...sharedStyles,
}));

const StyledMediaItem = styled.div<{ slidesCount: number }>(({ theme, ...props }) => ({
    position: "relative",
    borderRadius: theme.kaltura.shape.roundness1,
    backgroundColor: theme.kaltura.palette.surfaces.paper,
    overflow: "hidden",
    "& .media-thumbnail__duration": {
        fontWeight: theme.kaltura.typography.fontWeightBold,
        backgroundColor: alpha(theme.palette.common.black, 0.6),
        borderRadius: theme.kaltura.shape.roundness1,
        fontSize: theme.kaltura.typography.fontSize,
        padding: "4px 5px",
    },
    // In most cases, the carousel item's container already defines item's width,
    // so the component just needs to take it all
    width: "100%",
    [theme.breakpoints.up("xl")]: {
        ...(
            props.slidesCount === 2 && {
                [Data as never]: {
                    marginTop: 4,
                },
            }
        ),
    },
}));

/** Component to display media item in playlist carousel
 */
export const CarouselItem = ({
    item: entry,
    categoryId,
    className = "",
    noLink = false,
    currentCardsNumberInSlides,
    minPossibleCardsNumberInSlides,
    playlistId,
    tabIndex,
    onThumbnailResize,
}: CarouselItemProps) => {
    const config = useConfig();
    const { timeDisplay, dateFormats } = config.application;

    let linkUrl: string | undefined = useEntryUrl({ entry, categoryId, playlistId });
    if (noLink) {
        linkUrl = undefined;
    }

    let ariaLabelText = translate("watch %1", [entry.name]);
    if (entry.duration) {
        ariaLabelText = translate("watch %1, duration: %2", [entry.name, durationToHuman(entry.duration)]);
    }

    let liveStatus = WebcastStatus.noScheduling;
    let formattedTime = "";
    const timeFormat = timeDisplay === TimeDisplay.TwentyFourHours ? "H:mm" : "h:mm A"; // 15:00 || 3:00 PM

    if (entry.schedulingData) {
        // date | 09:00AM – 10:00AM
        const formattedStartTime = translateTime(
            entry.schedulingData.start.timestamp,
            `${dateFormats.shortDate} | ${timeFormat} `
        ).toUpperCase();
        const formattedEndTime = translateTime(entry.schedulingData.end.timestamp, timeFormat);
        formattedTime = formattedStartTime + " - " + formattedEndTime;

        liveStatus = WebcastHelper.getLiveStatus(entry.schedulingData);
    }

    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.lg));

    const sendButtonAnalytics = useButtonAnalytics();

    const sendAnalytics = () => {
        sendButtonAnalytics("Playlist - Media Thumbnail", ButtonClickAnalyticsType.THUMBNAIL);
    };

    const containerSizeTracker = useElementSizeObserver();

    useEffect(() => {
        if (containerSizeTracker?.height && onThumbnailResize) {
            onThumbnailResize(containerSizeTracker.height);
        }
    }, [containerSizeTracker?.height, onThumbnailResize]);
    // Render a div instead of a link if item not clickable
    const Item = noLink ? StyledDiv : StyledItemLink;

    return (
        <Item href={linkUrl} aria-label={ariaLabelText} tabIndex={tabIndex}>
            <StyledMediaItem
                className={`kms-ds-carousel-item-inner ${className}`}
                slidesCount={minPossibleCardsNumberInSlides}
            >
                <StyledMediaImage className="kms-ds-carousel-item-image">
                    <StyledThumbnail
                        ref={containerSizeTracker.elementRef}
                        thumbnailUrl={formatEntryThumbnailUrl(entry.thumbnailUrl, "379", "213")}
                        duration={entry.duration}
                        onClick={sendAnalytics}
                        altText={""} //thumbnail cannot be focused, item itself has dedicated aria-label, alt text should be empty
                        tabIndex={-1}
                    />
                </StyledMediaImage>
                <ItemDetailsWrapper
                    className="kms-ds-carousel-item-text-wrapper"
                    slidesCount={minPossibleCardsNumberInSlides}
                >
                    <StyledTruncatedName
                        lines={liveStatus === WebcastStatus.live || currentCardsNumberInSlides === 2 ? 1 : 2}
                        showMore={false}
                        className="kms-ds-carousel-item-title"
                    >
                        {entry.name}
                    </StyledTruncatedName>
                    {liveStatus === WebcastStatus.live && (
                        <DataContainer className={"chromatic-ignore kms-ds-carousel-item-description"}>
                            <Data>{formattedTime}</Data>
                            <StatusTag>{translate("Live")}</StatusTag>
                        </DataContainer>
                    )}
                    {minPossibleCardsNumberInSlides === 2 && liveStatus !== WebcastStatus.live && (
                        <StyledTruncatedDescription
                            lines={isTabletOrMobile ? 3 : 2}
                            showMore={false}
                            className="kms-ds-carousel-item-description"
                        >
                            {stripTags(entry.description)}
                        </StyledTruncatedDescription>
                    )}
                </ItemDetailsWrapper>
            </StyledMediaItem>
        </Item>
    );
};

export const CarouselItemSkeleton = ({
    minPossibleCardsNumberInSlides,
}: Pick<CarouselItemProps, "minPossibleCardsNumberInSlides">) => {
    return (
        <StyledMediaItem className={"kms-ds-carousel-item-inner"} slidesCount={minPossibleCardsNumberInSlides}>
            {/* render empty inner elements that define container's size */}
            <StyledMediaImage />
            <ItemDetailsWrapper slidesCount={minPossibleCardsNumberInSlides} />

            {/* render skeleton element that takes all container's size */}
            <Skeleton
                variant={"rectangular"}
                sx={{ position: "absolute", top: 0, left: 0, width: "100%", height: "100%" }}
            />
        </StyledMediaItem>
    );
};
