import { UploadStatus } from "@kaltura/mediaspace-shared-upload";
import { StyledBulkActionsRow, StyledLabel, StyledText } from "./StyleComponents";
import { translate } from "@kaltura/mediaspace-shared-utils";
import Thumbnail from "./thumbnail/Thumbnail";
import BulkActionsButtons from "./bulk-actions-buttons/BulkActionsButtons";
import { useEffect, useState } from "react";
import { MediaUploadFileInfo } from "@kaltura/mediaspace-shared-upload";

export interface BulkActionsProps {
    files: MediaUploadFileInfo[];
    entryIds: string[];
    onCancel: () => void;
    onEdit: () => void;
    onPublish: () => void;
    publishEnabled?: boolean;
}

const BulkActions = ({ files, entryIds, onCancel, onEdit, onPublish, publishEnabled }: BulkActionsProps) => {
    const [actionFiles, setActionFiles] = useState<MediaUploadFileInfo[]>([]);

    useEffect(() => {
        setActionFiles(
            files.filter((file) => {
                const status = file.fileMetaData?.status;
                return (
                    status === UploadStatus.IN_QUEUE
                    || status === UploadStatus.UPLOADING
                    || status === UploadStatus.PROCESSING
                    || status === UploadStatus.READY
                );
            })
        );
    }, [files]);

    return actionFiles.length <= 1 ? null : (
        <StyledBulkActionsRow className={"kms-ds-upload-media-bulk-actions-row"}>
            <Thumbnail filesCount={actionFiles.length} />
            <StyledLabel className={"kms-ds-upload-media-bulk-actions-row-thumbnail-label"}>
                <StyledText variant={"body1Highlight"}>{translate("All Media")}</StyledText>
            </StyledLabel>
            <BulkActionsButtons
                files={actionFiles}
                entryIds={entryIds}
                onCancel={onCancel}
                onEdit={onEdit}
                onPublish={onPublish}
                publishEnabled={publishEnabled}
            />
        </StyledBulkActionsRow>
    );
};

export default BulkActions;
