import { translatePlural } from "./kms";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin
import advancedFormat from "dayjs/plugin/advancedFormat";
import { timezoneAbbr } from "@kaltura/ds-react-utils";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(timezoneAbbr);
dayjs.extend(advancedFormat);

const formatters = {
    formatSecondsToTime(t: number) {
        if (t >= 3600) {
            return dayjs("1900-01-01 00:00:00").add(t, "seconds").format("HH:mm:ss");
        }
        else {
            return dayjs("1900-01-01 00:00:00").add(t, "seconds").format("mm:ss");
        }
    },
};

/**
 * formats unix timestamp in the given format
 * @param timestamp     in seconds
 * @param dateFormat    required format
 * @param timeZone      timezone to use when formatting
 */
const translateTime = (timestamp: number, dateFormat: string, timeZone?: string): string => {
    if (!timeZone) {
        timeZone = dayjs.tz.guess();
    }
    return dayjs.tz(timestamp * 1000, timeZone).format(dateFormat);
};

const durationToHuman = (duration: number): string => {
    duration = Math.floor(duration);
    if (duration < 60) {
        return translatePlural("%1 second", "%1 seconds", [duration]);
    }

    if (duration >= 60 && duration < 3600) {
        //return translatePlural("%1 minute", "%1 minutes", [getMinutes(duration)]);
        return `${translatePlural("%1 minute", "%1 minutes", [getMinutes(duration)])}, ${translatePlural(
            "%1 second",
            "%1 seconds",
            [getSeconds(duration)]
        )}`;
    }

    return `${translatePlural("%1 hour", "%1 hours", [getTotalHours(duration)])}, ${translatePlural(
        "%1 minute",
        "%1 minutes",
        [getMinutes(duration)]
    )}`;
};

export const getDays = (duration: number) => {
    return Math.floor(duration / 86400);
};

export const getTotalHours = (duration: number) => {
    return Math.floor(duration / 3600);
};

export const getHours = (duration: number) => {
    return Math.floor((duration % 86400) / 3600);
};

export const getMinutes = (duration: number) => {
    return Math.floor((duration % 3600) / 60);
};

export const getSeconds = (duration: number) => {
    return duration % 60;
};

/*
 * replace tags in the entry's original description:
 * new lines and &nbsp to spaces
 * replace &amp; with &
 * remove all HTML tags that do not match the provided allowedTags (for example: ['a','img', 'b'])
 */
const stripTags = (description: string, allowedTags: string[] = []) => {
    if (description) {
        const pattern = new RegExp(
            allowedTags.length > 0 ? `<\\/?((?!(${allowedTags.join("|")})\\b)\\w+)[^>]*>` : `<([^>]+)>`,
            "gi"
        );
        description = description
            .replace(/(<|&lt;)br\s*\/*(>|&gt;)/g, " ")
            .replace(/(<|&lt;)p\s*\/*(>|&gt;)/g, " ")
            .replace(/&nbsp;/g, " ")
            .replace(/&amp;/g, "&")
            .replace(pattern, "");
    }

    return description;
};

const humanReadableSize = (sizeInBytes: number): string => {
    const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
    if (sizeInBytes === 0) {
        return "0 Bytes";
    }
    const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
    if (i === 0) {
        return sizeInBytes + " " + sizes[i];
    }
    return (sizeInBytes / Math.pow(1024, i)).toFixed(1) + " " + sizes[i];
};

/**
 * Taken from: https://stackoverflow.com/questions/46155/how-to-validate-an-email-address-in-javascript
 */
const validEmailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/g
);
const emailRegExString =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

/*
 * format number larger than 1000
 * for example: 1400 -> 1.4K
 */
const formattedNumber = (num: number) => {
    // no formatting if number is smaller than 1000
    if (num < 1000) {
        return num;
    }
    const si = [
        { v: 1e3, s: "K" },
        { v: 1e6, s: "M" },
        { v: 1e9, s: "B" },
    ];
    // detect what mapping option to use, according to amount of digits
    let index;
    for (index = si.length - 1; index > 0; index--) {
        if (num >= si[index].v) {
            break;
        }
    }
    const divisionResult = num / si[index].v;
    const result = num % si[index].v === 0 ? divisionResult : divisionResult.toFixed(1);
    // return first digit after the dot + corresponding letter
    return result + " " + si[index].s;
};

export {
    translateTime,
    durationToHuman,
    stripTags,
    emailRegExString,
    humanReadableSize,
    validEmailRegex,
    formatters as default,
    formattedNumber,
};
