import { EditFormProps } from "./EditFormProps";
import { UseEditFormResponse } from "./useEditForm";
import { FormProvider } from "react-hook-form";
import { Field } from "./FieldsFactory";
import { ErrorMessage } from "./StyleComponents";
import { noop, translate } from "@kaltura/mediaspace-shared-utils";
import { ConfirmationModal } from "@kaltura/mediaspace-shared-ui";

/**
 * The UI of the entry edit form.
 */
export const EditFormContents = (props: Pick<EditFormProps, "formData" | "fieldsStructure"> & UseEditFormResponse) => {
    const { formData, fieldsStructure, useFormHookMethods, modalData, handleLeave } = props;

    return (
        <FormProvider {...useFormHookMethods}>
            {fieldsStructure.map((field) => {
                const isFieldRequired = field.required;
                return (
                    <div key={field.id}>
                        {Field(field, formData)}
                        {isFieldRequired && useFormHookMethods.formState.errors[field.id] && (
                            <ErrorMessage>
                                {translate("%1 is a required field", [field.label || field.id])}
                            </ErrorMessage>
                        )}
                    </div>
                );
            })}

            <ConfirmationModal
                open={!!modalData}
                noButtonLabel={translate("Leave")}
                noCallback={handleLeave}
                yesButtonLabel={modalData?.yesButtonLabel}
                yesCallback={modalData?.yesCallback || noop}
                title={modalData?.title}
                text={modalData?.text}
            />
        </FormProvider>
    );
};
