import { createRoot } from "react-dom/client";
import React, { ComponentType, Suspense } from "react";
import { ThemeProvider } from "@kaltura/mediaspace-shared-contexts";
import { Config, ConfigProvider } from "@kaltura/mediaspace-shared-data-kms-config";

const Login = React.lazy(() => import("@mediaspace/features/login/login/Login"));

const ForgotPassword = React.lazy(() => import("@mediaspace/features/login/forgot-password/ForgotPassword"));

const PasswordExpired = React.lazy(() => import("@mediaspace/features/login/password-expired/PasswordExpired"));
const ResetPassword = React.lazy(() => import("@mediaspace/features/login/reset-password/ResetPassword"));

const SetPassword = React.lazy(() => import("@mediaspace/features/login/set-password/SetPassword"));

const LoginSelection = React.lazy(() => import("@mediaspace/features/login/login-selection/LoginSelection"));

/**
 * this will be used to render other components/pages
 */
const render = (
    containerId: string,
    props: React.ComponentProps<any> & { context: Config; component: ComponentType<any> }
) => {
    const { context, component: Component, ...rest } = props;
    const { theming } = context;
    const root = createRoot(document.getElementById(containerId)!);
    root.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={context}>
                <ThemeProvider overrides={theming?.theme} mode={theming?.mode}>
                    <Component {...rest} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>
    );
};

export { render, Login, ForgotPassword, PasswordExpired, ResetPassword, SetPassword, LoginSelection };
