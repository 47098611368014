import { forwardRef, useContext, useEffect, useId, useImperativeHandle, useRef } from "react";
import { UnisphereContext } from "@kaltura/mediaspace-shared-contexts";

export interface UnisphereWidgetHandle {
    getWidget: () => any;
}

interface UnisphereWidgetProps {
    widgetName: string;
    context: string;
    settings: any;
}

/**
 * Component to render unisphere widget.
 * It uses the `forwardRef` and `useImperativeHandle` hooks to expose a method `getWidget`
 * that allows parent components to access the widget instance and invoke public methods on it.
 */
const UnisphereWidget = forwardRef<UnisphereWidgetHandle, UnisphereWidgetProps>(
    ({ widgetName, context, settings }, ref) => {
        const { unisphereWorkspace } = useContext(UnisphereContext);

        const isAreaAssignedRef = useRef(false);
        const containerId = useId();

        /**
         * useImperativeHandle customizes the instance value that is exposed when using ref.
         * Here, it exposes a method `getWidget` that allows parent components to access
         * the widget instance from the Unisphere workspace and invoke public methods on a given widget.
         */
        useImperativeHandle(
            ref,
            () => ({
                getWidget() {
                    return unisphereWorkspace?.getElement(widgetName, context);
                },
            }),
            [unisphereWorkspace, widgetName, context]
        );

        useEffect(() => {
            if (!unisphereWorkspace) return;

            unisphereWorkspace.loadElement(widgetName, context, settings).then(({ element }) => {
                if (!isAreaAssignedRef.current) {
                    element.assignArea(containerId);
                    isAreaAssignedRef.current = true;
                }
            });

            return () => {
                if (isAreaAssignedRef.current) {
                    unisphereWorkspace?.getElement(widgetName, context)?.unassignArea();
                    isAreaAssignedRef.current = false;
                }
            };
        }, [unisphereWorkspace, widgetName, context, settings, containerId]);

        return <div id={containerId} />;
    }
);

export default UnisphereWidget;
