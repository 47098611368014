/**
 * Meeting room types - representation of Newrow_Model_Newrow::ROOM_TYPE_* constants in php
 */
export enum MeetingRoomType {
    channel = "channel",
    private = "private_room",
    personal = "personal_room",
    resource = "resource",
    entry = "entry",
}
