import styled from "@emotion/styled";
import { KmsTypePublishCategories } from "@kaltura/mediaspace-shared-types";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { CategoryBubbleType } from "../../../../../../media-categories/category-bubbles/CategoryBubble";
import { enrichAndCombineCategories, EnrichmentType } from "../../../../../../utils";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { CategoriesList } from "../../../../../../media-categories/categories-list/CategoriesList";

export interface ExceptionsTabProps {
    entryIds: string[];
    channels: KmsTypePublishCategories;
    galleries: KmsTypePublishCategories;
    onCategoryChange: (categoryId: number, checked: boolean) => void;
    isBulk?: boolean;
}

const StyledContainer = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
}));

/**
 * publish modal exceptions tab
 * shows entry pending+rejected categories in current instance
 */
export function ExceptionsTab({ entryIds, channels, galleries, onCategoryChange, isBulk }: ExceptionsTabProps) {
    const sendButtonAnalytics = useButtonAnalytics();

    // get the pending categories
    const { pending: pendingChannels = [] } = channels;
    const { pending: pendingGalleries = [] } = galleries;
    const pendingLocations = enrichAndCombineCategories(pendingChannels, pendingGalleries, EnrichmentType.label);

    // get the rejected categories
    const { rejected: rejectedChannels = [] } = channels;
    const { rejected: rejectedGalleries = [] } = galleries;
    const rejectedLocations = enrichAndCombineCategories(rejectedChannels, rejectedGalleries, EnrichmentType.label);

    const entryId = entryIds.length === 1 ? entryIds[0] : undefined;

    const handleDelete = (category: CategoryBubbleType) => {
        sendButtonAnalytics(
            `Publish - Set publishing settings modal box - Remove media from exception ${isBulk && "(bulk)"}`,
            ButtonClickAnalyticsType.DELETE,
            entryId
        );
        onCategoryChange(category.id, false);
    };

    return (
        <StyledContainer>
            <CategoriesList
                categories={pendingLocations}
                title={{ label: translate("Pending in:"), color: "warning.main" }}
                onDelete={handleDelete}
            />
            <CategoriesList
                categories={rejectedLocations}
                title={{ label: translate("Rejected in:"), color: "error.main" }}
                onDelete={handleDelete}
            />
        </StyledContainer>
    );
}

export default ExceptionsTab;
