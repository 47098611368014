import { FileRowProps } from "./../FileRow";
import { Clear24Icon, Edit24Icon, ExternalLink24Icon } from "@kaltura/ds-react-icons";
import { getEntryUrl, translate, useMediaQuery } from "@kaltura/mediaspace-shared-utils";
import { FileMetaData, UploadStatus } from "@kaltura/mediaspace-shared-upload";
import { StyledRowActions } from "./StyleComponents";
import { useTheme } from "@kaltura/mediaspace-shared-styled";
import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import { ChangingButton } from "@kaltura/mediaspace-shared-ui";
import AsyncPublishMenuButton from "../../../media-actions/items/publish/AsyncPublishMenuButton";

export interface RowActionsProps
    extends Pick<FileRowProps, "isOpen" | "onCancel" | "onEdit" | "onRemove" | "publishEnabled">,
        Omit<FileMetaData, "progress"> {
    entryId?: string;
    showEditButton?: boolean;
    showRemoveButton?: boolean;
    entryName?: string;
    /**
     * used to disable publish button
     * during single entry edit submit
     */
    isEditProcessing: boolean;
    /**
     * used to remount entry publish button
     * and refresh its props data after edit entry or bulk publish
     */
    publishRefreshKey: number;
}

const RowActions = ({
    tokenId,
    entryId,
    status,
    isOpen,
    onCancel,
    onEdit,
    onRemove,
    showEditButton,
    showRemoveButton,
    entryName,
    isEditProcessing,
    publishEnabled,
    publishRefreshKey,
}: RowActionsProps) => {
    const theme = useTheme();
    const context = useConfig();

    const isXsScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
    const calcEntryName = entryName ?? entryId ?? translate("untitled");

    const isKaf = !!context.application.isKAF;

    return (
        <StyledRowActions className={"kms-ds-upload-media-file-row-actions"}>
            {(status === UploadStatus.IN_QUEUE || status === UploadStatus.UPLOADING) && (
                <ChangingButton
                    icon={<Clear24Icon />}
                    onClick={() => onCancel(tokenId)}
                    label={translate("Cancel Upload")}
                    ariaLabel={translate("Cancel upload for entry %1", [calcEntryName])}
                />
            )}
            {entryId && (status === UploadStatus.READY || status === UploadStatus.PROCESSING) && !isKaf && (
                <ChangingButton
                    icon={<ExternalLink24Icon />}
                    href={getEntryUrl(entryId)}
                    target={"_blank"}
                    label={translate("Media Page")}
                    ariaLabel={translate("Open Media page for entry %1", [calcEntryName])}
                />
            )}
            {!isXsScreen
                && publishEnabled
                && entryId
                && status !== UploadStatus.UPLOAD_FAILED
                && status !== UploadStatus.ENTRY_FAILED
                && status !== UploadStatus.CANCELED
                && status !== UploadStatus.PRE_QUEUE && (
                    <AsyncPublishMenuButton key={publishRefreshKey} entryId={entryId} isIdle={isEditProcessing} />
                )}
            {showEditButton
                && status !== UploadStatus.UPLOAD_FAILED
                && status !== UploadStatus.ENTRY_FAILED
                && status !== UploadStatus.CANCELED
                && status !== UploadStatus.PRE_QUEUE && (
                    <ChangingButton
                        icon={<Edit24Icon />}
                        onClick={() => onEdit(tokenId)}
                        selected={isOpen}
                        label={translate("Edit")}
                        ariaLabel={translate("Edit details for entry %1", [calcEntryName])}
                    />
                )}
            {showRemoveButton
                && (
                    status === UploadStatus.UPLOAD_FAILED
                    || status === UploadStatus.ENTRY_FAILED
                    || status === UploadStatus.CANCELED
                ) && (
                    <ChangingButton
                        icon={<Clear24Icon />}
                        onClick={() => onRemove(tokenId)}
                        label={translate("Remove")}
                        ariaLabel={translate("Remove entry %1 upload", [calcEntryName])}
                    />
                )}
        </StyledRowActions>
    );
};
export default RowActions;
