import { useContext, useEffect } from "react";
import { PubSubServiceType } from "@unisphere/core-js";
import { UnisphereContext } from "@kaltura/mediaspace-shared-contexts";
import { UnisphereMessage } from "@unisphere/core-js/src/lib/types/shared";
import { useEventHandler } from "@kaltura/mediaspace-shared-hooks";

const SERVICE_TYPE = "unisphere.service.pub-sub";

/**
 * Custom hook to subscribe to Unisphere PubSub events.
 *
 * This hook takes an event name and a callback function as parameters.
 * It subscribes to the specified event using the Unisphere PubSub service
 * and invokes the callback function whenever the event is triggered.
 *
 * The hook also handles the cleanup by unsubscribing from the event
 * when the component using this hook is unmounted or when the dependencies change.
 */
export const useUnispherePubSub = (eventName: string, callback: (data: UnisphereMessage) => void) => {
    const { unisphereWorkspace } = useContext(UnisphereContext);
    const memoizedCallback = useEventHandler(callback);
    useEffect(() => {
        if (!unisphereWorkspace) return;

        const unispherePubSubServiceUnsubscribe = unisphereWorkspace
            .getService<PubSubServiceType>(SERVICE_TYPE)
            ?.subscribe(eventName, memoizedCallback);

        return () => {
            if (unispherePubSubServiceUnsubscribe) {
                unispherePubSubServiceUnsubscribe();
            }
        };
    }, [unisphereWorkspace, eventName, memoizedCallback]);
};
