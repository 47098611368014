import { useEffect, useState } from "react";
import { BulkActionsProps } from "./../BulkActions";
import { Clear24Icon, Edit24Icon, ExternalLink24Icon, Lock24Icon } from "@kaltura/ds-react-icons";
import { addBaseUrl, translate, useMediaQuery } from "@kaltura/mediaspace-shared-utils";
import { StyledRowActions } from "./../../fileRow/row-actions/StyleComponents";
import { UploadStatus } from "@kaltura/mediaspace-shared-upload";
import { ChangingButton } from "@kaltura/mediaspace-shared-ui";
import { useTheme } from "@mui/material";
import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import { PublishMenuButton } from "../../../media-actions/items";

function BulkActionsButtons({ files, entryIds, onCancel, onEdit, onPublish, publishEnabled }: BulkActionsProps) {
    const theme = useTheme();
    const context = useConfig();
    const isXsScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

    const [showCancelAllButton, setShowCancelAllButton] = useState(false);
    const [showEditAllButton, setShowEditAllButton] = useState(false);

    useEffect(() => {
        const index = files.findIndex(
            (file) =>
                file.fileMetaData.status === UploadStatus.IN_QUEUE
                || file.fileMetaData.status === UploadStatus.UPLOADING
        );
        setShowCancelAllButton(index !== -1);

        const i = files.findIndex(
            (file) =>
                file.fileMetaData.status === UploadStatus.READY
                || file.fileMetaData.status === UploadStatus.PROCESSING
                || file.fileMetaData.status === UploadStatus.UPLOADING
        );
        setShowEditAllButton(i !== -1);
    }, [files]);

    const myMediaTarget = context.application.isKAF ? "" : { target: "_blank" };

    return (
        <StyledRowActions className={"kms-ds-upload-media-bulk-actions-row-actions"}>
            {showCancelAllButton ? (
                <ChangingButton
                    icon={<Clear24Icon />}
                    onClick={() => onCancel()}
                    label={translate("Cancel All Uploads")}
                />
            ) : (
                <ChangingButton
                    icon={<ExternalLink24Icon />}
                    href={addBaseUrl("/my-media")}
                    {...myMediaTarget}
                    label={translate("My Media")}
                />
            )}
            {!isXsScreen && publishEnabled && (
                <PublishMenuButton
                    entryIds={entryIds}
                    label={translate("Set Privacy To All")}
                    icon={<Lock24Icon />}
                    buttonAnalyticsActionName={"Set privacy to all"}
                    isBulk={true}
                    reloadMedia={onPublish}
                    enableUnlisted={context.application.enableUnlisted}
                />
            )}
            <ChangingButton
                icon={<Edit24Icon />}
                onClick={() => onEdit()}
                label={translate("Edit All")}
                disabled={!showEditAllButton}
            />
        </StyledRowActions>
    );
}

export default BulkActionsButtons;
