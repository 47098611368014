import { PublishMenuButton, PublishMenuButtonProps } from "./PublishMenuButton";
import { useEffect, useState } from "react";
import { baseUrl, getKmsData } from "@kaltura/mediaspace-shared-utils";

export interface AsyncPublishMenuButtonProps {
    entryId: string;
    isIdle?: boolean;
}

/**
 * async wrapper to the PublishMenuButton component
 * asynchronously get publish menu props for a given entry id
 * used in:
 * upload media single entry action, express recorder single entry action
 */
export function AsyncPublishMenuButton({ entryId, isIdle }: AsyncPublishMenuButtonProps) {
    const [data, setData] = useState<PublishMenuButtonProps | null>(null);
    const [isLoading, setIsLoading] = useState(true);

    /**
     * get publish menu props
     */
    useEffect(() => {
        (async () => {
            try {
                setIsLoading(true);
                const url = `${baseUrl}/publishmedia/media/get-component-props/entryId/${entryId}`;
                const fetchedProps = await getKmsData(url);

                if (fetchedProps) {
                    setData(fetchedProps);
                    setIsLoading(false);
                }
            }
            catch (error) {
                console.error("Error getting publish button props for entry id:", entryId);
            }
        })();
    }, [entryId]);

    /**
     * update menu state data by publish response
     */
    const handleItemStateChange = (key: string, value: any) => {
        setData((prevData) => {
            if (!prevData) return null;
            return {
                ...prevData,
                [key]: value, // Update the key with the new value
            };
        });
    };

    return (
        <PublishMenuButton
            entryIds={[entryId]}
            isLoading={isIdle || isLoading}
            onItemStateChange={handleItemStateChange}
            buttonAnalyticsActionName={"View publishing status"}
            {...data}
        />
    );
}

export default AsyncPublishMenuButton;
