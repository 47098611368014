import dayjs from "dayjs";

// kms uses zh-Hans and zh-Hant as Chinese language codes
// dayjs uses zh-cn and zh-tw as Chinese language codes, which is according to an older standard
// this mapping is used to convert kms language codes to dayjs language codes
const localeMapping: { [key: string]: string } = {
    "zh-Hans": "zh-cn",
    "zh-Hant": "zh-tw",
};

/**
 * set locale language.
 */
export default class SetLocalCodeHelper {
    static setLocalLanguage = (currentLocaleCode = "en") => {
        if (currentLocaleCode && currentLocaleCode !== "en") {
            // format locale code
            const formattedLocaleCode = localeMapping[currentLocaleCode] || currentLocaleCode;
            // set default locale for dayjs
            try {
                require("dayjs/locale/" + formattedLocaleCode);
                dayjs.locale(formattedLocaleCode);
            }
            catch (error) {
                console.log("error while attempting loading dayjs locale", formattedLocaleCode, error);
            }
        }
    };
}
