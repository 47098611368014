import { AsyncJobsContext } from "./AsyncJobsContext";
import { ReactNode, useMemo, useState } from "react";

export interface AsyncJobsProviderProps {
    children?: ReactNode;
    initValue?: boolean;
}

/**
 * Context provider for controlling server-polling for async jobs completion.
 * if init value is set to true, the checker will start polling immediately.
 * if init value is set to false, the checker will wait for the startPolling function to be called.
 *
 * Usage:
 * ```
 * <AsyncJobsProvider initValue={false} >
 *     <AsyncJobsChecker />
 *     <PageContents />
 * </AsyncJobsProvider>
 *
 * const PageContents = () => {
 *     const { shouldCheckAsyncJobsStatus, startPolling } = useAsyncJobsContext();
 *
 *     return <Button onClick={() => startPolling();}>
 *         current status: {shouldCheckAsyncJobsStatus ? "Should Check" : "Shouldn't Check"}
 *     </Button>;
 * };
 * ```
 */
export const AsyncJobsProvider = (props: AsyncJobsProviderProps) => {
    const [value, setValue] = useState(props.initValue || false);

    const asyncJobsContextValue = useMemo(() => {
        return {
            startPolling: () => setValue(true),
            resetStatus: () => setValue(false),
            shouldCheckAsyncJobsStatus: value,
        };
    }, [value]);

    return <AsyncJobsContext.Provider value={asyncJobsContextValue}>{props.children}</AsyncJobsContext.Provider>;
};
