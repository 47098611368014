import { generateUtilityClass, generateUtilityClasses } from "@kaltura/mediaspace-shared-styled";

export function getSiteHeaderClass(slot: string) {
    return generateUtilityClass("kms-ds-site-header", slot);
}

export interface SimpleSiteHeaderClasses {
    /**  styles applied to the root element */
    root: string;
    /**  styles applied to the logo element */
    logo: string;
    /**  styles applied to the title element */
    title: string;
    /**  styles applied to the language element */
    language: string;
    /**  styles applied to the overlay element */
    containerOverlay: string;
    /**  styles applied to the overlay element */
    languageOverlay: string;
}

export type SiteHeaderClassKey = keyof SimpleSiteHeaderClasses;

export const siteHeaderClasses = generateUtilityClasses(
    "kms-ds-registration-site-header",
    ["root", "logo", "title", "language", "container-overlay", "language-overlay"]
);

export default siteHeaderClasses;
