import { MenuItem } from "@kaltura/ds-react-components";
import { useState } from "react";
import { CloneKmeRoomModal, CloneKmeRoomProps } from "./modal/CloneKmeRoomModal";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { translate } from "@kaltura/mediaspace-shared-utils";

/**
 * Clone KME room menu item for media-page title area actions menu
 */
export const CloneKmeRoomMenuItem = (props: CloneKmeRoomProps) => {
    const { autoFocus, ...modalProps } = props;

    const [isOpen, setIsOpen] = useState(false);

    const sendButtonAnalytics = useButtonAnalytics();

    const openModal = () => {
        sendButtonAnalytics("Media actions - Clone room", ButtonClickAnalyticsType.ADD);
        setIsOpen(true);
    };

    return (
        <>
            <MenuItem onClick={openModal} autoFocus={autoFocus}>
                {translate("Clone room")}
            </MenuItem>

            <CloneKmeRoomModal {...modalProps} isOpen={isOpen} />
        </>
    );
};
