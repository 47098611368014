import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import { generalHtmlStyles } from "./../GeneralHtmlStyles";

/**
 * message wrapper, used for centering content on screen
 */
export const Wrapper = styled.div`
    align-items: center;
    display: flex;
    flex-direction: column;
`;

/**
 * inner message wrapper, used for centered content
 */
export const InnerContainer = styled(
    "div",
    {
        shouldForwardProp: (prop) =>
            prop !== "contentWidth" && prop !== "centerContent" && prop !== "adjustVerticalPadding",
    }
)<{ contentWidth?: number; centerContent?: boolean; adjustVerticalPadding?: boolean }>(
    ({ theme, contentWidth = 475, centerContent = false, adjustVerticalPadding = false }) => ({
        maxWidth: contentWidth,
        marginTop: theme.spacing(7.5),
        marginBottom: theme.spacing(7.5),
        padding: theme.spacing(6),
        ...(
            centerContent && {
                textAlign: "center",
            }
        ),
        [theme.breakpoints.down("sm")]: {
            width: "100%",
            marginTop: adjustVerticalPadding ? theme.spacing(5.5) : theme.spacing(7.5),
            padding: adjustVerticalPadding ? theme.spacing(0, 2) : theme.spacing(2),
        },
    })
);

/**
 * message title
 */
export const Title = styled(
    Typography,
    {
        shouldForwardProp: (prop) => prop !== "titleColor",
    }
)<{ titleColor?: string }>(({ theme, titleColor }) => ({
    display: "block",
    textAlign: "center",
    color: titleColor ?? theme.kaltura.palette.tone1,
}));

/**
 * message text
 */
export const BodyText = styled(
    Typography,
    {
        shouldForwardProp: (prop) => prop !== "textColor",
    }
)<{ textColor?: string }>(({ theme, textColor }) => [
    generalHtmlStyles(theme),
    {
        color: textColor ?? theme.kaltura.palette.tone2,
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        display: "block",
    },
]);
