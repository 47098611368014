import { Truncate } from "@kaltura/mediaspace-shared-ui";
import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import { KmsTypeAddNewMenuItem, KmsTypeComponent } from "@kaltura/mediaspace-shared-types";
import { useTheme } from "@kaltura/mediaspace-shared-styled";
import { ComponentType, useMemo } from "react";
import { getComponentFromPath } from "@kaltura/mediaspace-shared-utils";

export interface AddNewIllustrationProps {
    item: KmsTypeAddNewMenuItem;
}

const StyledIllustrationContainer = styled("div")(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    height: "100%",
    width: "50%",
    boxSizing: "border-box",
    minHeight: 400,
    textAlign: "center",
    backgroundColor: theme.kaltura.palette.surfaces.paper,
    padding: theme.spacing(2),
    [theme.breakpoints.down("md")]: {
        display: "none",
    },
}));

const StyledStickyContainer = styled("div")(({ theme }) => ({
    top: "50%",
    position: "sticky",
    paddingTop: theme.spacing(2),
}));

const StyledIllustrationWrapper = styled("div")({
    height: "50%",
    position: "relative",
});

const StyledDataWrapper = styled("div")({
    height: "50%",
});

const StyledIllustration = styled("img")({
    width: 200,
    height: 200,
    position: "absolute",
    bottom: 0,
    left: 6,
});

const StyledSvgContainer = styled("div")({
    position: "absolute",
    bottom: 0,
    left: 6,
    "& svg": {
        width: 200,
        height: 200,
    },
});

const StyledIlustrationTitle = styled(Typography)({
    width: "100%",
    display: "block",
});

const StyledIlustrationDescription = styled(Truncate)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(14),
    color: theme.kaltura.palette.tone2,
    width: "100%",
}));

/**
 * `Add New` menu illustration
 * This component is responsible for rendering the illustration and description for the "Add New" menu items.
 * It handles both image-based illustrations (via a URL) and SVG illustrations (as StyledIllustration React component).
 * If an SVG illustration is supplied through `styledIllustrationSvg`, it will take precedence over
 * the `illustrationUrl`, meaning the URL-based image will be ignored in favor of the provided SVG.
 */
export function AddNewIllustration(props: AddNewIllustrationProps) {
    const { item } = props;
    const theme = useTheme();
    const isDarkMode = theme.palette.mode === "dark";

    const illustrationSrc = useMemo(() => {
        if (!item.illustrationUrl || item.styledIllustrationSvg) {
            return null;
        }
        return typeof item.illustrationUrl === "string"
            ? item.illustrationUrl
            : isDarkMode
              ? item.illustrationUrl.dark
              : item.illustrationUrl.light;
    }, [item.illustrationUrl, item.styledIllustrationSvg, isDarkMode]);

    let StyledIllustrationSvg: ComponentType | null = null;
    if (item.styledIllustrationSvg) {
        const styledIllustration: KmsTypeComponent = item.styledIllustrationSvg;
        StyledIllustrationSvg = getComponentFromPath(styledIllustration.component);
    }

    return (
        <StyledIllustrationContainer role="presentation">
            <StyledStickyContainer>
                <StyledIllustrationWrapper>
                    {illustrationSrc && <StyledIllustration aria-hidden={true} src={illustrationSrc} />}
                    {StyledIllustrationSvg && (
                        <StyledSvgContainer>
                            <StyledIllustrationSvg />
                        </StyledSvgContainer>
                    )}
                </StyledIllustrationWrapper>
                <StyledDataWrapper>
                    <StyledIlustrationTitle variant="button">{item.label}</StyledIlustrationTitle>
                    <StyledIlustrationDescription aria-hidden={true} lines={7} showMore={false}>
                        {item.description}
                    </StyledIlustrationDescription>
                </StyledDataWrapper>
            </StyledStickyContainer>
        </StyledIllustrationContainer>
    );
}

export default AddNewIllustration;
