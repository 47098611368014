import { ComponentType, Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ThemeProvider, ToastsProvider } from "@kaltura/mediaspace-shared-contexts";
import { Config, ConfigProvider } from "@kaltura/mediaspace-shared-data-kms-config";

/**
 * Render standalone component in DS context.
 * This function could be used directly on the PHP side, e.g.
 * ```
 * <div id="some-id"></div>
 * <script>
 *     MEDIASPACE.DsLayout.renderStandaloneComponent(
 *         'some-id',
 *         MEDIASPACE.AppName.ComponentReference,
 *         <?php echo $this->jsCodeEcho($this->props + ['context' => $configContext]); ?>
 *     );
 * </script>
 * ```
 */
export const renderStandaloneComponent = <T,>(
    id: string,
    Component: ComponentType<T>,
    props: T & { context: Config },
    scoped = false
) => {
    const { theming } = props.context;
    const root = createRoot(document.getElementById(id)!);
    root.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming?.theme} mode={theming?.mode} scoped={scoped}>
                    <ToastsProvider toastDuration={4000}>
                        <Component {...props} />
                    </ToastsProvider>
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>
    );
};

/**
 * Return a render function for rendering standalone component in DS context.
 * This helper could be used in a React app to prepare a component render function for the client side.
 * The render function takes element ID as the first argument, and component's props with a context object as the second argument,
 * so it would be used like the following on the PHP side:
 * ```
 * <div id="some-id"></div>
 * <script>
 *     MEDIASPACE.AppName.render('some-id', <?php echo $this->jsCodeEcho($this->props + ['context' => $configContext]); ?>);
 * </script>
 * ```
 */
export const getStandaloneComponentRenderer =
    <T,>(component: ComponentType<T>, scoped = false) =>
    (id: string, props: T & { context: Config }) =>
        renderStandaloneComponent(id, component, props, scoped);
