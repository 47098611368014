import { PropsWithChildren, useState, MouseEvent } from "react";
import styled from "@emotion/styled";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { KmsTypePresenter, ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { EventPresenterModal } from "../event-presenter-modal/EventPresenterModal";

const StyledAnchor = styled("a")({
    textDecoration: "none",
});

type EventPresenterWithModalProps = {
    className: string;
    /** presenter to show */
    presenter: KmsTypePresenter;
    /**
     * show presenter modal on click
     */
    showPresenterModal?: boolean;
};

/**
 * event presenter with modal functionality
 */
export function EventPresenterWithModal(props: PropsWithChildren<EventPresenterWithModalProps>) {
    const { className, showPresenterModal = false, presenter, children } = props;

    const sendButtonAnalytics = useButtonAnalytics();

    const [renderModal, setRenderModal] = useState(false);
    const [openModal, setOpenModal] = useState(false);

    const handleClick = (event: MouseEvent) => {
        setRenderModal(showPresenterModal);
        setOpenModal(showPresenterModal);
        showPresenterModal && sendButtonAnalytics("User modal box - Show more", ButtonClickAnalyticsType.EXPAND);
        event.preventDefault();
    };

    const handleCloseModal = () => {
        setOpenModal(false);
        // close analytics beacons are handled by the modal itself.
    };

    if (showPresenterModal) {
        return (
            <>
                <StyledAnchor
                    role="button"
                    aria-label={translate("Open info box for %1, %2", [presenter.name, presenter.title ?? ""])}
                    className={className}
                    href=""
                    onClick={handleClick}
                >
                    {children}
                </StyledAnchor>
                {/* EventPresenterModal fetches data from kms, so render it on demand */}
                {renderModal && <EventPresenterModal open={openModal} onClose={handleCloseModal} user={presenter} />}
            </>
        );
    }
    else {
        return <div className={className}>{children}</div>;
    }
}
