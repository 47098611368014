import { StyledIllustration } from "@kaltura/mediaspace-shared-ui";

export const ExpressCaptureIllustration = () => (
    <StyledIllustration
        fileName={"AddNewExpressCapture.svg"}
        width="201"
        height="200"
        viewBox="0 0 201 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="60.6895"
            y="92.9824"
            width="10.6667"
            height="17.3333"
            rx="5.33333"
            transform="rotate(-6 60.6895 92.9824)"
            className="Tone1"
            fill="#333333"
        />
        <rect
            x="46.8624"
            y="101.67"
            width="100"
            height="70.6667"
            rx="8"
            transform="rotate(-6 46.8624 101.67)"
            fill="#006EFA"
        />
        <mask
            id="mask0_55669_12295"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="47"
            y="92"
            width="106"
            height="80"
        >
            <rect
                x="46.8624"
                y="101.67"
                width="100"
                height="70.6667"
                rx="8"
                transform="rotate(-6 46.8624 101.67)"
                fill="#006EFA"
            />
        </mask>
        <g mask="url(#mask0_55669_12295)">
            <path d="M48.5349 121.582L148.65 111.06" stroke="white" strokeWidth="2" />
            <path d="M53.4128 161.326L153.528 150.804" stroke="white" strokeWidth="2" />
        </g>
        <ellipse
            cx="100.879"
            cy="132.253"
            rx="20.0957"
            ry="20.0957"
            transform="rotate(-6 100.879 132.253)"
            fill="#333333"
        />
        <circle cx="100.879" cy="132.253" r="14" transform="rotate(-6 100.879 132.253)" fill="white" />
        <ellipse cx="129.912" cy="100.985" rx="4" ry="4" transform="rotate(-6 129.912 100.985)" fill="#FF3D23" />
        <path
            d="M139.758 79.6399L141.155 84.8413"
            className="sTone1"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M156.816 89.2917L151.632 90.7532"
            className="sTone1"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M150.399 80.7529L147.768 85.4517"
            className="sTone1"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <rect
            width="44"
            height="22.6667"
            rx="11.3333"
            transform="matrix(-0.999087 -0.0427192 -0.0427192 0.999087 126.287 53.874)"
            fill="#FFD357"
        />
        <path
            d="M107.985 81.735L96.4921 69.8447L110.158 70.4291L109.703 81.0828C109.665 81.9605 108.595 82.3667 107.985 81.735Z"
            fill="#FFD357"
        />
        <ellipse
            cx="2.66667"
            cy="2.66667"
            rx="2.66667"
            ry="2.66667"
            transform="matrix(-0.999087 -0.0427192 -0.0427192 0.999087 117.258 62.1631)"
            fill="#333333"
        />
        <ellipse
            cx="2.66667"
            cy="2.66667"
            rx="2.66667"
            ry="2.66667"
            transform="matrix(-0.999087 -0.0427192 -0.0427192 0.999087 106.601 61.707)"
            fill="#333333"
        />
        <ellipse
            cx="2.66667"
            cy="2.66667"
            rx="2.66667"
            ry="2.66667"
            transform="matrix(-0.999087 -0.0427192 -0.0427192 0.999087 95.9441 61.251)"
            fill="#333333"
        />
        <rect
            x="128.5"
            y="31.4639"
            width="35.3333"
            height="26.6667"
            rx="13.3333"
            transform="rotate(-12.1948 128.5 31.4639)"
            fill="#FF3D23"
        />
        <path
            d="M145.186 59.7647L153.348 48.6016L141.618 51.1364L143.401 59.3858C143.584 60.2292 144.677 60.4613 145.186 59.7647Z"
            fill="#FF3D23"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M148.43 38.4995C148.43 38.4995 149.608 35.4517 151.919 35.4341C154.231 35.4166 156.009 38.0004 155.02 40.6364C154.125 43.0227 150.922 46.3219 150.002 46.9081C149.905 46.9694 149.771 46.9984 149.619 46.984C146.482 46.6871 144.185 44.9305 142.668 43.3058C142.668 43.3058 140.156 40.1919 142.673 37.8548C145.19 35.5176 148.43 38.4995 148.43 38.4995Z"
            fill="white"
        />
        <circle cx="111.174" cy="21.4196" r="12.5685" transform="rotate(-20.3899 111.174 21.4196)" fill="#FF9DFF" />
        <path
            d="M115.441 18.4825C116.928 18.881 117.426 20.7399 116.337 21.8286L111.584 26.5819C110.495 27.6705 108.636 27.1725 108.238 25.6853L106.498 19.1922C106.1 17.7051 107.46 16.3442 108.948 16.7427L115.441 18.4825Z"
            fill="white"
        />
    </StyledIllustration>
);
