import { useContext } from "react";
import { HeaderMenuScrollContext } from "./HeaderMenuScrollContext";
import { CSSObject } from "@emotion/styled";
import { contrastTextShadow } from "@kaltura/mediaspace-shared-ui";

/**
 * custom hook to provide menu items with text shadow css according to the HeaderMenuScrollContext
 */
export const useHeaderTextStyle = (): CSSObject => {
    const { headerColor, scrollRatio } = useContext(HeaderMenuScrollContext);

    return {
        textShadow: headerColor === "transparent" && scrollRatio === 0 ? contrastTextShadow : "none",
        transition: "text-shadow 0.5s ease",
    };
};
