import { StyledIllustration } from "@kaltura/mediaspace-shared-ui";

export const InteractiveIllustration = () => (
    <StyledIllustration
        fileName={"AddNewInteractive.svg"}
        width="201"
        height="200"
        viewBox="0 0 201 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M17.8331 86.9999C1.1664 66.3333 23.1664 53.8748 41.8331 55.6666"
            className="sTone1"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M85.833 55.0005C114.5 57.0005 111.166 30.0005 129.833 32.0005C135.833 32.6434 157.5 26.6671 154.833 60.6671"
            className="sTone1"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M59.167 164.565C61.6373 162.319 65.0974 160.874 68.1825 159.741C96.8303 149.215 125.085 173.065 153.227 161.52C157.796 159.645 162.568 156.612 164.805 152.037C171.848 137.64 153.589 126.254 153.725 136.403C153.771 139.899 156.441 142.786 159.574 144.031C167.537 147.197 176.356 140.186 177.304 132.257C180.101 108.856 143.86 113.047 131.5 120"
            className="sTone1"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <rect
            x="49.5039"
            y="157.243"
            width="20"
            height="16"
            rx="8"
            transform="rotate(-14.6985 49.5039 157.243)"
            fill="#FF3D23"
        />
        <rect
            x="54.7822"
            y="85.3369"
            width="82.6667"
            height="54.6667"
            rx="8"
            transform="rotate(-5.3899 54.7822 85.3369)"
            fill="#FF9DFF"
        />
        <path d="M67.0361 130.05L133.408 123.788" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
        <circle cx="98.1874" cy="105.348" r="12" transform="rotate(-5.3899 98.1874 105.348)" fill="white" />
        <path
            d="M103.25 104.001C103.95 104.321 104.04 105.279 103.412 105.725L96.823 110.406C96.1955 110.852 95.3206 110.451 95.2483 109.685L94.4891 101.638C94.4167 100.872 95.2012 100.314 95.9011 100.635L103.25 104.001Z"
            fill="#006EFA"
        />
        <rect
            x="123.051"
            y="60.4053"
            width="50.6667"
            height="38.6667"
            rx="4"
            transform="rotate(-2.38622 123.051 60.4053)"
            className="Tone1"
            fill="#333333"
        />
        <circle cx="129.295" cy="66.1503" r="2" transform="rotate(-2.38622 129.295 66.1503)" fill="#FF3D23" />
        <ellipse cx="135.957" cy="65.873" rx="2" ry="2" transform="rotate(-2.38622 135.957 65.873)" fill="#FFD357" />
        <circle
            cx="142.617"
            cy="65.5947"
            r="1"
            transform="rotate(-2.38622 142.617 65.5947)"
            className="sTone8"
            stroke="white"
            strokeWidth="2"
        />
        <path
            d="M134.985 74.5869L166.292 73.2823"
            className="sTone8"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M130.655 82.7744L159.297 81.5809"
            className="sTone8"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <path
            d="M144.311 90.2129L166.958 89.2692"
            className="sTone8"
            stroke="white"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <ellipse cx="133.833" cy="32.6663" rx="9.33334" ry="9.33333" fill="#FF9DFF" />
        <rect
            x="38.8076"
            y="38.1758"
            width="50.6667"
            height="36.6667"
            rx="8"
            transform="rotate(-3.3433 38.8076 38.1758)"
            fill="#006EFA"
        />
        <rect
            x="7.52832"
            y="87.7812"
            width="20"
            height="20"
            rx="8"
            transform="rotate(-26.2095 7.52832 87.7812)"
            fill="#FFD357"
        />
    </StyledIllustration>
);
