import { useMemo, useCallback, useContext } from "react";
import {
    KmsTypePublishUserCategories,
    KmsTypePublishCategories,
    KmsTypePublishCategory,
} from "@kaltura/mediaspace-shared-types";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { GalleryCheckbox, GalleryCheckboxProps } from "./GalleryCheckbox";
import { ConfigContext } from "@kaltura/mediaspace-shared-data-kms-config";

export interface GalleriesTabFormProps {
    galleries: KmsTypePublishCategories;
    userCategories: KmsTypePublishUserCategories;
    galleryResults?: KmsTypePublishCategory[];
    onCategoryChange: (categoryId: number, checked: boolean) => void;
    isBulk?: boolean;
}

/**
 * publish modal Galleries tab Form (checkboxes)
 */
export function GalleriesTabForm(props: GalleriesTabFormProps) {
    const { galleries, userCategories, onCategoryChange, galleryResults = [], isBulk } = props;
    const { galleries: userGalleries } = userCategories;
    const { published = [], pending = [] } = galleries;

    const configContext = useContext(ConfigContext);

    const galleryLocations = userGalleries || galleryResults;

    const isChecked = useCallback(
        (galleryId: number) => {
            return !!(
                published.find((gallery) => gallery.id === galleryId)
                || pending.find((gallery) => gallery.id === galleryId)
            );
        },
        [published, pending]
    );

    const sendButtonAnalytics = useButtonAnalytics();

    const handleCategoryChange = useCallback(
        (id: number, checked: boolean) => {
            checked
                && sendButtonAnalytics(
                    `Publish - Set publish settings modal box - Gallery checked ${isBulk && "(bulk)"}`,
                    ButtonClickAnalyticsType.TOGGLE
                );
            !checked
                && sendButtonAnalytics(
                    `Publish - Set publish settings modal box - Gallery unchecked ${isBulk && "(bulk)"}`,
                    ButtonClickAnalyticsType.TOGGLE
                );

            onCategoryChange(id, checked);
        },
        [onCategoryChange]
    );

    // build gallery tree
    const calculateTree = useCallback(
        (galleryLocations: KmsTypePublishCategory[]) => {
            const galleriesTree: Pick<GalleryCheckboxProps, "gallery" | "items"> = { gallery: undefined, items: {} };

            const rootGallery = configContext.categories.rootGalleryCategoryId;
            galleryLocations.forEach((gallery) => {
                // get ids, from root gallery onward
                const fullIds = gallery.fullIds.split(">");
                const rootIndex = fullIds.indexOf(rootGallery);
                const idArray = rootIndex !== -1 ? fullIds.slice(rootIndex + 1) : fullIds;

                const nameArray = gallery.fullName.split(">");
                const delta = nameArray.length - idArray.length;

                let currentGallery = galleriesTree;

                idArray.forEach((id, index) => {
                    // current location name
                    const name = nameArray[index + delta];

                    // current location - default is disabled gallery, until replaced by real one
                    currentGallery["items"][id] = currentGallery["items"][id] ?? {
                        items: {},
                        gallery: { name: name, id: id },
                        disabled: true,
                    };

                    // leaf
                    if (index === idArray.length - 1) {
                        currentGallery["items"][id].gallery = gallery;
                        currentGallery["items"][id].disabled = false;
                    }

                    // next location
                    currentGallery = currentGallery["items"][id];
                });
            });
            return galleriesTree;
        },
        [configContext.categories.rootGalleryCategoryId]
    );

    const galleriesTree = useMemo(() => calculateTree(galleryLocations ?? []), [calculateTree, galleryLocations]);

    return (
        <GalleryCheckbox
            {...props}
            depth={1}
            isChecked={isChecked}
            onCategoryChange={handleCategoryChange}
            {...galleriesTree}
        ></GalleryCheckbox>
    );
}
