import React from "react";
import { styled } from "@mui/material";
import { Skeleton, useTheme } from "@kaltura/mediaspace-shared-styled";

const StyledEventListSkeleton = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    borderRadius: theme.kaltura.shape.roundness2,
    marginTop: theme.spacing(2),
}));

/**
 * event List Skeleton
 */
export function EventListSkeleton() {
    const theme = useTheme();

    return (
        <StyledEventListSkeleton>
            <StyledSkeleton variant="rectangular" height={theme.spacing(16)} width={"100%"} />
            <StyledSkeleton variant="rectangular" height={theme.spacing(16)} width={"100%"} />
        </StyledEventListSkeleton>
    );
}

export default EventListSkeleton;
