import styled from "@emotion/styled";
import { Alert } from "@kaltura/ds-react-components";
import { KmsTypePublishEntryCategories } from "@kaltura/mediaspace-shared-types";
import { CategoryBubblesContainer } from "../../../../../media-categories/category-bubbles/CategoryBubblesContainer";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { enrichAndCombineCategories, EnrichmentType } from "../../../../../utils";
import { useEffect } from "react";

export interface PublishedCategoriesProps {
    entryCategories: KmsTypePublishEntryCategories;
    entryIds: string[];
    onLocationsCount: (count: number) => void;
    onDelete: (categoryId: number) => void;
    isBulk?: boolean;
}

const StyledAlert = styled(Alert)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

const StyledPublishedCategories = styled(CategoryBubblesContainer)(({ theme }) => ({
    marginBottom: theme.spacing(1),
}));

/**
 * publish modal locations - published(+ pending + rejected) categories
 */
export function PublishedCategories({
    entryCategories,
    entryIds,
    onLocationsCount,
    onDelete,
    isBulk,
}: PublishedCategoriesProps) {
    const { channels = {}, galleries = {}, privateCategories } = entryCategories;

    // get the published categories
    const { published: publishedChannels = [] } = channels;
    const { published: publishedGalleries = [] } = galleries;
    const publishedLocations = enrichAndCombineCategories(publishedChannels, publishedGalleries, EnrichmentType.label);
    // get the pending categories
    const { pending: pendingChannels = [] } = channels;
    const { pending: pendingGalleries = [] } = galleries;
    const pendingLocations = enrichAndCombineCategories(pendingChannels, pendingGalleries, EnrichmentType.label);

    // get the rejected categories
    const { rejected: rejectedChannels = [] } = channels;
    const { rejected: rejectedGalleries = [] } = galleries;
    const rejectedLocations = enrichAndCombineCategories(rejectedChannels, rejectedGalleries, EnrichmentType.label);

    const publishedCategories = [...publishedLocations, ...pendingLocations, ...rejectedLocations];

    useEffect(() => {
        onLocationsCount(publishedCategories.length);
    }, [onLocationsCount, publishedCategories.length]);

    return (
        <>
            {privateCategories && (
                <StyledAlert severity="warning">{translate("Media is published to a private location")}</StyledAlert>
            )}

            <StyledPublishedCategories
                categories={publishedCategories}
                entryIds={entryIds}
                onDelete={onDelete}
                isCollapsable={true}
                className="kms-ds-publish-media-locations-published"
                isBulk={isBulk}
            />
        </>
    );
}
