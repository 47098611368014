import React, { createContext, PropsWithChildren, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ConfigContext } from "@kaltura/mediaspace-shared-data-kms-config";
import { useTheme } from "@mui/material";
import { UnisphereWorkspaceType } from "@unisphere/core-js";

export const UnisphereContext = createContext<{
    unisphereWorkspace: UnisphereWorkspaceType | null;
}>({
    unisphereWorkspace: null,
});

const loadUnisphereWorkspace = async (loaderPath: string, options: any) => {
    const { loader } = await import(/* @vite-ignore */ /* webpackIgnore: true */ loaderPath);
    return loader(options);
};

export const UnisphereProvider = ({ children }: PropsWithChildren) => {
    const [unisphereWorkspace, setUnisphereWorkspace] = useState<UnisphereWorkspaceType | null>(null);

    const config = useContext(ConfigContext);
    const serverUrl = config.application.unisphereWorkspaceUrl;
    const application = config.application.isKAF ? "KAF" : "KMS";
    const theme = useTheme();

    const useLoadingRef = useRef(false);

    useEffect(() => {
        const loadWorkspace = async () => {
            if (unisphereWorkspace || !serverUrl) {
                return;
            }

            if (useLoadingRef.current) {
                return;
            }

            useLoadingRef.current = true;

            try {
                const workspace = await loadUnisphereWorkspace(
                    // appending unispherePresets=none to the URL to prevent the Unisphere workspace from loading Unisphere devtools
                    `${serverUrl}/loader/index.esm.js?unispherePresets=none`,
                    {
                        serverUrl,
                        application,
                        modules: [],
                        ui: {
                            theme: theme.palette,
                            language: config.application.currentLocaleCode ?? "en",
                        },
                    }
                );
                setUnisphereWorkspace(workspace);
            }
            catch (error) {
                console.error(`[unisphere.${application}] error loading the Unisphere workspace:`, error);
            }
        };

        loadWorkspace();
    }, [unisphereWorkspace]);

    const value = useMemo(() => {
        return {
            unisphereWorkspace,
        };
    }, [unisphereWorkspace]);

    return <UnisphereContext.Provider value={value}>{children}</UnisphereContext.Provider>;
};
