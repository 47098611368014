/**
 * helper to check newrow room status
 */
import { baseUrl } from "@kaltura/mediaspace-shared-utils";
import { browserName, browserVersion } from "react-device-detect";
import { MeetingRoomType } from "./MeetingRoomType";

export class MeetingRoomHelper {
    /**
     * check if the room connected to the given entry is currently live
     * @param entryId
     */
    static pollRoom(entryId: string): Promise<boolean> {
        if (!entryId) {
            return Promise.reject("missing entry id");
        }
        // go to KMS url with the entry id to check if the room is live
        const url = baseUrl + "/meetingentry/newrow/check-alive/entryid/" + entryId + "?format=script";
        const myRequest = new Request(url);

        return fetch(myRequest).then(function (response: Response) {
            if (!response.ok) {
                return Promise.reject(response);
            }

            return Promise.resolve(response.json());
        });
    }

    static updateRoomStatus(type: MeetingRoomType, roomId: string): void {
        // go to KMS url with the entry id to set the room status active
        const url = `${baseUrl}/newrow/index/room-live/type/${type}/roomId/${roomId}?format=ajax`;
        const myRequest = new Request(url);

        fetch(myRequest).then(function (response: Response) {
            if (!response.ok) {
                console.log(`failed to update room status for ${type} ${roomId}`);
            }
        });
    }

    /**
     * update room status in KMS as closed
     */
    static clearRoomStatus(type: MeetingRoomType, roomId: string) {
        // go to KMS url with the entry id to set the room status active
        const url = `${baseUrl}/newrow/index/room-close/type/${type}/roomId/${roomId}?format=ajax`;
        const myRequest = new Request(url);

        return fetch(myRequest).then(function (response: Response) {
            if (!response.ok) {
                console.log(`failed to clear room status for ${type} id ${roomId}`);
            }
        });
    }

    /**
     * check if current browser supports the meeting room
     */
    static isNewrowSupportedInCurrentBrowser = (safariSupport = false) => {
        const isSupportedVersion = (name: string, version: number) => {
            if (browserName !== name) {
                return false;
            }
            return Number(browserVersion) > version;
        };
        return (
            isSupportedVersion("Chrome", 65)
            || isSupportedVersion("Firefox", 59)
            || isSupportedVersion("Edge", 81)
            || (safariSupport && ["Safari", "Mobile Safari"].includes(browserName))
        );
    };
}
