import { ESupportedActions } from "@kaltura/ds-react-richtext";
import { EntryMetadataStructure } from "@kaltura/mediaspace-shared-upload";
import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import {
    TextField,
    TagsField,
    RichtextField,
    DateField,
    DateWithTimeField,
    MultiTextField,
    MultiSelectField,
    SelectField,
} from "@kaltura/mediaspace-shared-ui/controlled-form";
import { EditFormElement } from "./EditFormElement";

export const Field = (field: EditFormElement, data?: EntryMetadataStructure) => {
    const config = useConfig();

    switch (field.fieldType) {
        case "autocomplete_element":
            return (
                <TagsField
                    url={field.url || ""}
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    value={data ? (data[field.id] as string) : ""}
                    label={field.label}
                    description={field.description}
                />
            );
        case "rich_text_element":
            return (
                <RichtextField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
                    value={data ? (data[field.id] as string) : ""}
                    description={field.description}
                    supportedActions={[
                        ESupportedActions.Bold,
                        ESupportedActions.Italic,
                        ESupportedActions.Underline,
                        ESupportedActions.List,
                        ESupportedActions.Link,
                        ESupportedActions.Image,
                    ]}
                />
            );
        case "text_element":
            return (
                <TextField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
                    required={field.required}
                    defaultValue={data ? (data[field.id] as string) : ""}
                    description={field.description}
                />
            );
        case "date_element":
            return (
                <DateField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
                    value={data ? (data[field.id] as string) : ""}
                    description={field.description}
                    dateFormat={config.application.shortDateFormat}
                />
            );
        case "date_time_element":
            return (
                <DateWithTimeField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
                    value={data ? (data[field.id] as string) : ""}
                    description={field.description}
                    dateFormat={config.application.shortDateFormat}
                />
            );
        case "multi_text_element":
            return (
                <MultiTextField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
                    value={data ? (data[field.id] as string[]) : []}
                    description={field.description}
                />
            );
        case "multi_select_list_element":
            return (
                <MultiSelectField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
                    value={data ? (data[field.id] as string[]) : []}
                    description={field.description}
                    options={field.options || []}
                />
            );
        case "list_element":
            return (
                <SelectField
                    id={field.belongsTo ? `${field.belongsTo}.${field.id}` : field.id}
                    key={field.id}
                    label={field.label}
                    value={data ? (data[field.id] as string) : undefined}
                    description={field.description}
                    options={(field.options || []).map((opt) => {
                        return { label: opt, value: opt };
                    })}
                />
            );
        default:
            return null;
    }
};
