import { StyledIllustration } from "@kaltura/mediaspace-shared-ui";

export const TownHallIllustration = () => (
    <StyledIllustration
        fileName={"TownHall.svg"}
        width="201"
        height="200"
        viewBox="0 0 201 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <ellipse cx="114.833" cy="81.667" rx="55" ry="55" fill="#FF3D23" />
        <ellipse
            cx="115.167"
            cy="31.3875"
            rx="18.3569"
            ry="18.3569"
            transform="rotate(-14.3458 115.167 31.3875)"
            fill="#006EFA"
        />
        <path
            d="M121.712 27.926C123.194 28.3414 123.671 30.2059 122.57 31.2821L114.893 38.7862C113.792 39.8623 111.938 39.3431 111.557 37.8515L108.897 27.4505C108.516 25.9589 109.892 24.6137 111.374 25.0292L121.712 27.926Z"
            fill="white"
        />
        <path
            d="M191.141 103.58C191.634 101.426 190.287 99.2813 188.134 98.7886L143.93 88.6755C141.776 88.1828 139.631 89.5292 139.139 91.6827L133.866 114.728C133.374 116.882 134.72 119.027 136.873 119.519L181.077 129.632C183.231 130.125 185.376 128.779 185.869 126.625L191.141 103.58Z"
            fill="#FFD357"
        />
        <path d="M173.392 109.842L179.913 105.924" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
        <path d="M178.737 111.064L174.567 104.701" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
        <path d="M147.224 98.4453L166.073 102.758" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
        <path d="M145.656 105.3L164.505 109.612" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
        <path d="M144.088 112.154L177.502 119.799" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
        <path
            d="M107.52 76.3606L104.143 76.9959C98.8139 77.9981 95.7397 83.6306 97.7794 88.6545C100.172 94.5468 107.919 95.8792 112.142 91.1247L114.425 88.5553C116.444 86.282 116.868 83.0054 115.495 80.2927C114.007 77.3542 110.757 75.7518 107.52 76.3606Z"
            fill="white"
        />
        <path
            d="M74.7367 152.742L56.1292 129.358L73.5385 125.422L85.2946 145.505C87.7984 149.783 84.7136 155.163 79.7573 155.163C77.8023 155.163 75.954 154.272 74.7367 152.742Z"
            fill="#FFD357"
        />
        <path
            d="M122.218 111.72L96.9929 56.5284C95.9346 54.213 93.0112 53.4622 90.9682 54.9812L30.6742 99.8112C27.4067 102.241 26.4852 106.737 28.5335 110.256L38.8416 127.965C40.5635 130.923 43.9765 132.458 47.3321 131.783L119.368 117.304C121.895 116.796 123.289 114.065 122.218 111.72Z"
            fill="#006EFA"
        />
        <path
            d="M82.3734 152.986C84.9612 156.808 83.5291 162.467 79.9568 165.375C76.3858 168.283 71.2971 168.742 66.857 167.558C62.4156 166.374 58.4995 163.748 54.8377 160.955C51.8517 158.678 48.5803 156.128 44.8432 156.382C41.1035 156.636 38.1629 159.643 35.937 162.677C33.527 165.963 31.484 169.526 29.9326 173.301C29.1037 175.321 28.4045 177.476 28.6004 179.653C28.7976 181.83 30.0869 184.032 32.1533 184.706C34.4518 185.456 37.1161 183.984 38.0721 181.749C39.0281 179.515 38.4405 176.804 36.9501 174.888C35.461 172.973 33.1936 171.787 30.8471 171.205C25.678 169.924 19.9005 171.518 16.1038 175.274C14.0426 177.311 12.5224 180.08 12.6093 182.986"
            className="sTone1"
            stroke="#333333"
            strokeWidth="2"
            strokeLinecap="round"
        />
        <mask
            id="mask0_55669_13815"
            style={{ maskType: "alpha" }}
            maskUnits="userSpaceOnUse"
            x="27"
            y="54"
            width="96"
            height="78"
        >
            <path
                d="M122.218 111.72L96.9929 56.5284C95.9346 54.213 93.0112 53.4622 90.9682 54.9812L30.6742 99.8112C27.4067 102.241 26.4852 106.737 28.5335 110.256L38.8416 127.965C40.5635 130.923 43.9765 132.458 47.3321 131.783L119.368 117.304C121.895 116.796 123.289 114.065 122.218 111.72Z"
                fill="#006EFA"
            />
        </mask>
        <g mask="url(#mask0_55669_13815)">
            <path d="M69.1665 65.334L96.7313 125.541" stroke="#333333" strokeWidth="2" strokeLinecap="round" />
        </g>
        <rect
            x="88.5"
            y="51.9775"
            width="6.46208"
            height="76.4858"
            rx="3.23104"
            transform="rotate(-24.1586 88.5 51.9775)"
            fill="#333333"
        />
        <rect
            x="129.46"
            y="52.8828"
            width="49.5713"
            height="19.9573"
            rx="4"
            transform="rotate(6.45261 129.46 52.8828)"
            className="Tone1"
            fill="#333333"
        />
        <path
            d="M168.579 70.6421C167.558 70.5266 166.691 69.8066 166.809 68.7626C166.884 68.1024 167.395 67.7093 167.837 67.5805C167.49 67.3469 167.24 66.9454 167.296 66.4541C167.397 65.5637 168.261 65.0705 169.197 65.1765C170.134 65.2824 170.866 65.9561 170.766 66.8466C170.71 67.3379 170.377 67.6733 169.987 67.8236C170.389 68.0479 170.799 68.5452 170.724 69.2054C170.606 70.2494 169.6 70.7576 168.579 70.6421ZM168.687 69.6902C169.163 69.7441 169.567 69.471 169.616 69.0334C169.667 68.5882 169.333 68.2395 168.857 68.1856C168.381 68.1318 167.978 68.3972 167.928 68.8424C167.878 69.28 168.211 69.6364 168.687 69.6902ZM169.722 66.7984C169.762 66.4376 169.476 66.1487 169.092 66.1053C168.709 66.0619 168.365 66.2796 168.324 66.6404C168.282 67.0166 168.569 67.2978 168.953 67.3412C169.336 67.3846 169.679 67.1746 169.722 66.7984Z"
            className="Tone8"
            fill="white"
        />
        <path
            d="M166.796 65.8379L164.175 70.043L162.916 69.9006L165.477 65.8831L163.036 65.607L163.15 64.5938L166.889 65.0166L166.796 65.8379Z"
            className="Tone8"
            fill="white"
        />
        <path
            d="M162.297 68.2369C162.185 69.2348 161.317 69.8209 160.112 69.6846C159.598 69.6265 159.129 69.4413 158.722 69.1231L158.852 67.9716C159.251 68.3666 159.677 68.5858 160.137 68.6379C160.698 68.7013 161.118 68.4922 161.168 68.047C161.213 67.6555 160.874 67.3451 160.329 67.2835L159.83 67.2271L159.931 66.3366L160.407 66.3904C160.852 66.4408 161.187 66.2299 161.224 65.9074C161.265 65.539 160.929 65.2832 160.499 65.2346C160.077 65.1869 159.675 65.3047 159.23 65.5887L159.358 64.4603C159.771 64.2427 160.239 64.1634 160.707 64.2164C161.751 64.3345 162.457 64.974 162.352 65.8952C162.297 66.3865 162.002 66.7263 161.577 66.9037C162.097 67.188 162.363 67.6612 162.297 68.2369Z"
            className="Tone8"
            fill="white"
        />
        <path
            d="M157.226 70.1665L156.559 70.0909L157.152 69.2407L157.145 69.2398C156.845 69.2059 156.628 68.9248 156.664 68.6101C156.708 68.2186 157.047 67.9693 157.416 68.011C157.807 68.0552 158.075 68.3731 158.03 68.7646C158.007 68.9719 157.924 69.1569 157.757 69.3946L157.226 70.1665Z"
            className="Tone8"
            fill="white"
        />
        <path
            d="M151.947 65.9926C152.11 64.5571 153.024 63.3466 154.513 63.515C156.002 63.6834 156.623 65.0676 156.461 66.5031C156.299 67.9386 155.384 69.1491 153.895 68.9807C152.406 68.8122 151.785 67.4281 151.947 65.9926ZM155.317 66.3737C155.413 65.5293 155.168 64.6697 154.393 64.582C153.617 64.4944 153.187 65.2775 153.091 66.1219C152.996 66.9664 153.24 67.8259 154.016 67.9136C154.791 68.0013 155.222 67.2181 155.317 66.3737Z"
            className="Tone8"
            fill="white"
        />
        <path
            d="M151.035 68.5566L147.458 68.152L147.549 67.3459L149.47 65.8294C150.015 65.4091 150.181 65.179 150.221 64.8259C150.268 64.4037 149.941 64.0635 149.435 64.0062C148.943 63.9507 148.452 64.1749 147.986 64.581L148.122 63.3758C148.571 63.0533 149.124 62.9059 149.646 62.965C150.705 63.0848 151.484 63.826 151.371 64.8316C151.292 65.5302 150.942 66.0115 150.204 66.5501L149.161 67.3184L151.149 67.5433L151.035 68.5566Z"
            className="Tone8"
            fill="white"
        />
        <ellipse
            cx="140.827"
            cy="61.5983"
            rx="2.23178"
            ry="2.23178"
            transform="rotate(6.45261 140.827 61.5983)"
            className="Tone8"
            fill="white"
        />
        <path
            d="M136.314 66.7512C136.525 64.8876 138.207 63.5478 140.07 63.7586L141.084 63.8732C142.947 64.0839 144.287 65.7655 144.076 67.629V67.629C144.045 67.9088 143.792 68.11 143.512 68.0784L136.764 67.3151C136.484 67.2835 136.283 67.031 136.314 66.7512V66.7512Z"
            className="Tone8"
            fill="white"
        />
    </StyledIllustration>
);
